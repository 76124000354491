import React, { 
    useEffect,
    useState
   } from 'react';
  import { useNavigate } from 'react-router-dom';
  import '@cds/core/card/register.js';
  import '@cds/core/progress-circle/register.js';
  
  import { Icon } from '@iconify/react';
  
  import { LoadGraphList } from '../../../../../services/Graph/GraphService';

  export default function JobGraphList( {FramePort, jobGraphList, setJobGraphList, refreshValue} ) {
  
    const navigate = useNavigate();
    const [isLoading, setLoadingMode] = useState(true);

    useEffect(() => {
        setLoadingMode(true);
        LoadGraphList(FramePort, 'job_graph_list')
        .then((response) => {
            let newList = removeOldJob(response);
            setJobGraphList(newList);
            setLoadingMode(false);
        }).catch((err) => {
            console.log(err)
    })

    }, [FramePort, refreshValue]);

    const loadProject = (graphID) => {
        navigate('/draw/job/project/' + graphID)
    }

    const removeOldJob = (graphList) => {
        const newList = []
        for (let i = 0; i < graphList.length; i++) {
            if ("metadata" in graphList[i]) {
                if ("graph" in graphList[i].metadata) {
                    newList.push(graphList[i])
                }
            }
        }
        return newList
    }

    return (
        <>
        <div className='startup_content project'>
                { isLoading && <div className='loading_project'>
                    <cds-progress-circle size="128" line="2"></cds-progress-circle>
                </div> || <>
                { jobGraphList.length > 0 && <div className='node-grid'>
                { jobGraphList.map((singleGraph, index) => (
                    <cds-card
                        class="projectCard"
                        cds-theme="dark"
                        key={singleGraph.id}
                        onClick={() => loadProject(singleGraph.id)}
                    >
                        <img src={singleGraph.metadata.graph.metadata.preview} style= {{ width: "100%"}}></img>
                        <div className='node-title'>
                            <h3 style={{margin: "0"}}>{singleGraph.name}</h3>
                            <div className='date'>Project: { singleGraph.project_id }</div>
                            <div className='date'>ID: { singleGraph.id }</div>
                        </div>
                    </cds-card>
                ))}
                </div> || 
                <div style={{
                    width: "100%", 
                    height:"100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <Icon icon="clarity:flow-chart-line" width="30%" height="30%" />
                        <div>No project yet.</div>
                    </div>    
                </div>} </>}
            </div>
    </>
    );
}