import React, { useState, useContext } from 'react';
import { Icon } from '@iconify/react';


interface CompatibilityIndicatorInterface {
    compatibleFramework: Record<LanguageName, Boolean>
}
function CompatibilityIndicator({ compatibleFramework }: CompatibilityIndicatorInterface) {

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
            border: '0.01rem solid',
            borderColor: '#0079ad',
            borderRadius: '0.2rem'
        }}>
            <Icon style={{ marginRight: '5px' }} icon="simple-icons:apachespark" color={compatibleFramework.pyspark ? '#0079ad' : '#a8abad'} />
            <Icon style={{ marginRight: '5px' }} icon="simple-icons:apachespark" color={compatibleFramework.java ? '#0079ad' : '#a8abad'} />
            <Icon style={{ marginRight: '5px' }} icon="simple-icons:apacheflink" color={compatibleFramework.flink ? '#0079ad' : '#a8abad'} />
            <Icon style={{ marginRight: '5px' }} icon="simple-icons:pandas" color={compatibleFramework.pandas ? '#0079ad' : '#a8abad'} />
            <Icon style={{ marginRight: '5px' }} icon="simple-icons:apache" color={compatibleFramework.beam ? '#0079ad' : '#a8abad'} />
            <Icon icon="simple-icons:dask" color={compatibleFramework.dask ? '#0079ad' : '#a8abad'} />
        </div>
    )
}

export default CompatibilityIndicator;