import FileObject from "./object/FileObject";
import ModelObject from "./object/ModelObject";

import DataStructure from "./DataStructure";
import S3Options from "./connector/S3Options";
import AzureOptions from "./connector/AzureOptions";
import SQLOptions from "./connector/SQLOptions";

import TrainParameters from "./model/TrainParameters";
import TestParameters from "./model/TestParameters";

import RandomForestParameters from "./model/RandomForestParameters";

import JobOptions from "../../Workflow/options/JobOptions";


function ParameterFactory(id, data, parameter_id) {
    // console.log(data)
    switch (parameter_id)
    {
      case "file_object":
      return(
          <FileObject
          key={parameter_id + "-" + id}
          id={id}
          data={data}
        />
      )
      case "model_object":
        return(
            <ModelObject
            key={parameter_id + "-" + id}
            id={id}
            data={data}
          />
        )
      case "s3_options":
        return(
            <S3Options
            key={parameter_id + "-" + id}
            id={id}
            data={data}
          />
      )
      case "azure_options":
        return(
            <AzureOptions
            key={parameter_id + "-" + id}
            id={id}
            data={data}
          />
      )
      case "sql_options":
        return(
            <SQLOptions
            key={parameter_id + "-" + id}
            id={id}
            data={data}
          />
      )
      case "data_structure":
          return(
              <DataStructure
              key={parameter_id + "-" + id}
              id={id}
              data={data}
            />
      )
      case "train_parameters":
        return(
            <TrainParameters
            key={parameter_id + "-" + id}
            id={id}
            data={data}
          />
      )
      case "test_parameters":
        return(
            <TestParameters
            key={parameter_id + "-" + id}
            id={id}
            data={data}
          />
      )
      case "random_forest_parameters":
        return(
            <RandomForestParameters
            key={parameter_id + "-" + id}
            id={id}
            data={data}
          />
      )
      case "job":
        return(
          <JobOptions
            key={parameter_id + "-" + id}
            id={id}
            data={data}
          />
        )
      default: 
          alert('Default case');
    }
}

export default ParameterFactory;
