import React, { 
  useEffect,
  useState
 } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '@cds/core/card/register.js';
import '@cds/core/progress-circle/register.js';
import '@cds/core/forms/register.js';
import { CdsButton } from '@cds/react/button';

import { LoadTemplateVersions, LoadTemplateMetadata, GenerateTemplate } from '../../services/TemplateService';
import { CreateGraph } from '../../services/Graph/GraphService';
import dagToGraph from '../../services/Graph/DagToGraph';

import './templateform.css'

export default function TemplateForm( {FramePort} ) {

  const navigate = useNavigate();

  const [isVersionsLoading, setVersionsLoadingMode] = useState(true)
  const [isTemplateLoading, setTemplateLoadingMode] = useState(true)
  const [templateVersions, setTemplateVersions] = useState([]);
  const [versionSelected, setVersionSelected] = useState("");
  const [templateMetadata, setTemplateMetadata] = useState("");
  const [templateForm, setTemplateForm] = useState({})
  const [isGraphLoading, setGraphLoading] = useState(false)

  let params = useParams();

  useEffect(() => {
    setVersionsLoadingMode(true)
    LoadTemplateVersions(params.templateId)
    .then((response) => {
        setTemplateVersions(response)
        setVersionSelected(response[0])
        setVersionsLoadingMode(false)
    }).catch((err) => {
        console.log(err)
        navigate('/notfound')
    })
  }, [FramePort]);

  useEffect(() => {
    if ( versionSelected != "") {
      setTemplateLoadingMode(true)
      LoadTemplateMetadata(params.templateId, versionSelected)
      .then((response) => {
        setTemplateMetadata(response)
        setTemplateLoadingMode(false)
      }).catch((err) => {
          console.log(err)
      })
    }
  }, [versionSelected]);

  const renderTemplate = (event) => {
    event.preventDefault();
    GenerateTemplate(
      params.templateId, 
      versionSelected,
      JSON.stringify(templateForm)
    ).then((response) => {
      setGraphLoading(true)
      console.log(response)
      let graph = dagToGraph(response)
      CreateGraph(
        "graph-" + params.templateId,
        graph
      )
      .then((newID) => {
          navigate('/draw/draft/' + newID);
      })
    }).catch((err) => {
        console.log(err)
    })
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let templateFormCopy = templateForm

    templateFormCopy[name] = value

    setTemplateForm(templateFormCopy)
  }

  return(
    <>
    {
    isGraphLoading
    &&
    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} cds-theme="dark">
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><cds-progress-circle size="128" line="2"></cds-progress-circle>Graph is loading..</div>
    </div> 
    ||
    <div style={{ width: '100vw', height: '100vh' }} cds-theme="dark">
      <div className='startup_main'>
        <h2>{params.templateId}</h2>
          { 
          isVersionsLoading 
          && 
          <div className='loading_project'>
              <cds-progress-circle size="128" line="2"></cds-progress-circle>
          </div> 
          ||
          <>
            <cds-select layout="vertical" style={{width: '400px'}}>
              <label>Template Version</label>
              <select className="nodrag" style={{width: '80px', fontSize: '10px'}}  onChange={(e) => setVersionSelected(e.target.value)} value={versionSelected} >
                { templateVersions.map((singleVersion, index) => (
                  <option value={singleVersion}>{singleVersion}</option>
                ))}
              </select>
            </cds-select>
            { 
              isTemplateLoading 
              && 
              <div className='loading_project'>
                  <cds-progress-circle size="128" line="2"></cds-progress-circle>
              </div> 
              ||
              <div className='template-metadata'>
                <h3>Description</h3>
                <div className='template-description'>
                  {templateMetadata.description}
                </div>  
                <h3>Parameters</h3>               
                <form onSubmit={renderTemplate}>
                  <cds-form-group control-width="shrink">
                    { templateMetadata.parameters.map((singleParameter, index) => (
                      <cds-input layout="vertical">
                        <label>{singleParameter.name}</label>
                        <input 
                        id="text"
                        name={singleParameter.name}
                        placeholder={singleParameter.example}
                        value={templateForm[singleParameter.name]}
                        onChange={handleInputChange}
                        required
                        className="nodrag" 
                        style={{width: '200px'}}
                        />
                        <cds-control-message>{singleParameter.description}</cds-control-message>
                      </cds-input>
                    ))}
                    <cds-button>Create</cds-button>
                  </cds-form-group>
                </form>
              </div>
            }
          </>
        }
      </div>
    </div>}
    </>
  )
}