import React, {
  useCallback,
  useEffect
} from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow
} from 'reactflow';
import { jobNodeTypes } from './components/Nodes/job_node_types.js'
import { workflowNodeTypes } from './components/Nodes/workflow_node_types.js';
import { edgeTypes } from './components/Nodes/edge_types.js';

import NodeLibrary from './components/NodeLibrary/NodeLibrary';

const initialNodes = [];
const initialEdges = [];

const proOptions = { hideAttribution: true };


interface DrawInterfaceInterface {
  resourceType: ResourceType
  currentStatus: NodeStatus;
}
export default function DrawInterface({ resourceType, currentStatus }: DrawInterfaceInterface) {

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const { getNode } = useReactFlow();

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        node.data = JSON.parse(JSON.stringify({
          ...node.data,
          status: currentStatus,
        }));

        return node;
      })
    )
  }, [currentStatus, setNodes])


  const onConnect = useCallback((params) => {
    const sourceType = getHandleType(getNode(params.source)?.data.node_data.output, params.sourceHandle)

    params["type"] = "custom";
    params["data"] = { type: sourceType }
    setEdges((eds) => addEdge(params, eds));
  }, [setEdges]);

  const isValidConnection = (params) => {
    // Checks if an new edge is valid
    const sourceType = getHandleType(getNode(params.source)?.data.node_data.output, params.sourceHandle)
    const targetType = getHandleType(getNode(params.target)?.data.node_data.input, params.targetHandle)
    return sourceType === targetType
  }

  const getHandleType = (handle_data, handle_id) => {
    for (const handle of handle_data) {
      if (handle.id == handle_id) {
        return handle.type
      }
    }
  }


  return (
    <>
      <NodeLibrary resourceType={resourceType} />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        isValidConnection={isValidConnection}
        nodeTypes={resourceType === "job" ? jobNodeTypes : workflowNodeTypes}
        edgeTypes={edgeTypes}
        proOptions={proOptions}
        deleteKeyCode={['Backspace', 'Delete']}
        fitView
      >

        <Controls />
        <MiniMap nodeStrokeWidth={3} zoomable pannable />
        <Background color="#365566" gap={12} size={1} />
      </ReactFlow>
    </>
  );
}