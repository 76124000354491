import React, { useState } from 'react';
import { useReactFlow, getRectOfNodes, getTransformForBounds  } from 'reactflow';
import { CdsButton } from '@cds/react/button';
import { ClarityIcons, plusIcon } from '@cds/core/icon';
import '@cds/core/table/table.min.css';

import DataTable from './components/DataTable';
import LogPanel from './components/LogPanel';

interface PreviewTabInterface {
  currentData: any;
  selectedNode: string[];
  logList: LogMessage[];
}
function PreviewTab({ currentData, selectedNode, logList}:PreviewTabInterface) {
      
    return (
      <div style={{ display: "flex", flexGrow: "1"}}>
        <div style={{display: "flex", flexDirection: "column", height: "100%", borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#82a1b5", fontFamily: "Clarity City", color: "white", width: "75%"}}>
            <div style={{ width: "100%", height: "fit-content", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#82a1b5"}}>
                <div style={{margin: "4px"}}>
                    Output
                </div>
            </div>
            <DataTable 
              currentData={currentData}
              selectedNode={selectedNode}
            />
        </div>
        <div style={{display: "flex", flexDirection: "column", flexGrow: "1", height: "100%", borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#82a1b5", fontFamily: "Clarity City", color: "white", borderLeftWidth: "1px", borderLeftStyle: "solid", borderLeftColor: "#82a1b5"}}>
            <div style={{ width: "100%", height: "fit-content", borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#82a1b5"}}>
                <div style={{margin: "4px"}}>
                    Logs
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", WebkitBoxFlex: "1", flexGrow: "1", overflow: "hidden"}}>
                <div style={{overflowY: "auto", WebkitBoxFlex: "1", flexGrow: "1", position: "relative"}}>
                  <div style={{position: "absolute", inset: "0px"}}>
                    <div style={{ overflowX: "auto"}}>
                      <LogPanel logList={logList} />
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    );
    }

export default PreviewTab;
