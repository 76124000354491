import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import Parameter from '../../Job/options/Parameter';

class JobOptions extends React.Component {

    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.open = false;
        this.handleJobChange = this.handleJobChange.bind(this);
    }

    render() {
        return (
            <Parameter
                header="Job Options">
                <cds-input layout="vertical">
                    <label>Job ID</label>
                    <input
                        id={this.id + "-storage-name"}
                        name="text"
                        className="nodrag"
                        onChange={(e) => this.handleJobChange(e)}
                        defaultValue={this.data.ref}
                    />
                </cds-input>
            </Parameter>
        )
    }

    handleJobChange(e, index) {
        const { name, value } = e.target;
        this.data.ref = value;
        this.forceUpdate();
    }

};


export default JobOptions;
