import React, { 
    useEffect,
    useState
    } from 'react';
import { useNavigate } from 'react-router-dom';
import '@cds/core/card/register.js';
import '@cds/core/progress-circle/register.js';
import { CdsButton } from '@cds/react/button';
import { Icon } from '@iconify/react';

import { LoadGraphList } from '../../../../../services/Graph/GraphService';

export default function AllGraphList( {FramePort, allGraphList, setAllGraphList, refreshValue} ) {
  
    const navigate = useNavigate();
    const [isLoading, setLoadingMode] = useState(true)
    const [userNameList, setUserNameList] = useState({});

    useEffect(() => {
    setLoadingMode(true)
    LoadGraphList(FramePort, 'all_graph_list')
        .then((response) => {
            setAllGraphList(response)
            setLoadingMode(false)
            sideLoadUserName()
        }).catch((err) => {
            console.log(err)
        })
    }, [FramePort, refreshValue]);

    const loadProject = (graphID, resourceType) => {
        navigate(`/draw/${resourceType}/draft/${graphID}`)
    }
    const duplicateProject = (graphID) => {
        // Todo
    }

    const parseDate = (graphDate) => {
        const graphDateParsed = new Date(Date.parse(graphDate))
        return graphDateParsed.getHours() + ":" + graphDateParsed.getMinutes() + ", " + graphDateParsed.toDateString();
    }

    const sideLoadUserName = () => {
        const userNameCopy = {...userNameList}
        for (let i = 0; i < allGraphList.length; i++) {
            if (userNameCopy[allGraphList[i].owner] === undefined || userNameCopy[allGraphList[i].owner] === null) {
                userNameCopy[allGraphList[i].owner] = "name"   
            }
        }
        setUserNameList(userNameCopy)
        console.log("username loaded")
    }

    return (
        <>
            <div className='startup_content project'>
                { isLoading && 
                <div className='loading_project'>
                    <cds-progress-circle size="128" line="2"></cds-progress-circle>
                </div> 
                || 
                <>
                    { allGraphList.length > 0 && 
                    <>
                        <div className='node-grid'>
                            { allGraphList.map((singleGraph, index) => (
                                <cds-card
                                    class="projectCard"
                                    cds-theme="dark"
                                    key={singleGraph.id}
                                    onClick={() => loadProject(singleGraph.id, singleGraph.type)}
                                >
                                    <img src={singleGraph.metadata.preview} style= {{ width: "100%"}}></img>
                                    <div className='node-title'>
                                        <h3 style={{margin: "0"}}>{singleGraph.name}</h3>
                                        <div className='date'>Created: { parseDate(singleGraph.created) }</div>
                                        <div className='date'>Owner: { 
                                        singleGraph.owner
                                        // userNameList[singleGraph.owner] === undefined && <>Loading...</> || userNameList[singleGraph.owner]
                                        }</div>
                                    </div>
                                </cds-card>
                            ))}
                        </div>
                        {/* <div className='load-project'>
                            <CdsButton
                                action="outline"
                                block
                            >
                                Load More
                            </CdsButton>
                        </div> */}
                    </>
                    || 
                    <div style={{
                        width: "100%", 
                        height:"100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"}}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Icon icon="clarity:flow-chart-line" width="30%" height="30%" />
                            <div>No project yet.</div>
                        </div>    
                    </div>} 
                </>}
            </div>
        </>
    );
}