import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import '@cds/core/range/register.js';
import Parameter from '../Parameter';
import Range from '../../../components/inputs/Range';


class RandomForestParameters extends React.Component {

    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.open = false;
      }
    
    render () {
        return(
            <Parameter
                header="Model parameters">
            <Range 
                label = "Tree Number"
                min = {1}
                max = {1000}
                step = {1}
                data = {this.data.params.number_trees}
                handleChange = {(e) => {this.data.params.number_trees = e}}
            />
            <Range 
                label = "Max depth"
                min = {1}
                max = {15}
                step = {1}
                data = {this.data.params.max_depth}
                handleChange = {(e) => {this.data.params.max_depth = e}}
            />
            </Parameter>
        )
    }

}

export default RandomForestParameters;
