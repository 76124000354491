import React, { useMemo } from 'react';
import { getConnectedEdges, Handle, useNodeId, useStore } from 'reactflow';

import "./Handle.css";

const selector = (s) => ({
    nodeInternals: s.nodeInternals,
    edges: s.edges,
});

const InputHandle = (props) => {
    const { nodeInternals, edges } = useStore(selector);
    const nodeId = useNodeId();

    const isHandleConnectable = useMemo(() => {
        
        if (typeof props.isConnectable === 'number') {
            const node = nodeInternals.get(nodeId);
            const connectedEdges = getConnectedEdges([node], edges);
            const connectedEdgesFiltered = connectedEdges.filter(edge => edge.targetHandle == props.id && edge.target == nodeId)
            return connectedEdgesFiltered.length < props.isConnectable;
        }

        return props.isConnectable;
    }, [nodeInternals, edges, nodeId, props.isConnectable]);

    return (
        <Handle {...props} isConnectable={isHandleConnectable}></Handle>
    );
};

export default InputHandle;
