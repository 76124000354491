import React, { useState, useEffect } from 'react';
import '@cds/core/card/register.js';
import ProjectButton from './components/ProjectButton/ProjectButton';
import SaveMenu from './components/SaveMenu/SaveMenu';
import InstanceMenu from './components/InstanceMenu/InstanceMenu';
import ExportMenu from './components/ExportMenu/ExportMenu';
import CompatibilityIndicator from './components/CompatibilityIndicator/CompatibilityIndicator';
import { CdsButton } from '@cds/react/button';
import { navigateTo } from '../../../../services/FrameUtils.js';
import { CreateWorkflow } from '../../../../services/Workflow/WorkflowService';
import { Icon } from '@iconify/react';

interface MainMenuInterface {
    graphName: string;
    graphId: string;
    drawMode: DrawMode;
    resourceType: ResourceType;
    graphMetadata: any;
    addLogMessage: (newMessage: LogMessage) => void;
    setCurrentData: any;
    setCurrentStatus: (value: NodeStatus) => void;
    compatibleFramework: Record<LanguageName, Boolean>;
    verifyDag: Function
}
function MainMenu(props: MainMenuInterface) {

    const [currentGraphName, setCurrentGraphName] = useState<string>(props.graphName);
    const [currentGraphID, setCurrentGraphID] = useState<string>(props.graphId);

    useEffect(() => {
        setCurrentGraphName(props.graphName);
    }, [props.graphName])

    const onNameChange = (event) => {
        const { name, value } = event.target;
        setCurrentGraphName(value);
    }

    return (
        <div style={{ width: '100vw', height: '5%', display: 'flex', alignItems: 'center', backgroundColor: "#0f171c", justifyContent: "space-between" }}>
            <div style={{ display: 'flex' }}>
                <ProjectButton />
                {
                    (props.resourceType === "job")
                    &&
                    <ExportMenu currentGraphID={currentGraphID} currentGraphName={currentGraphName} compatibleFramework={props.compatibleFramework} />
                }
                <SaveMenu currentGraphID={currentGraphID} currentGraphName={currentGraphName} drawMode={props.drawMode} resourceType={props.resourceType} addLogMessage={props.addLogMessage} compatibleFramework={props.compatibleFramework} />
                {
                    (props.drawMode === "project" && props.resourceType === "workflow")
                    &&
                    <CdsButton action="flat" status="primary" onClick={() => { navigateTo("/projects/" + props.graphMetadata.project_id + "/workflows/" + props.graphId) }}>Go to Workflow</CdsButton>
                }
                {
                    (props.drawMode === "project" && props.resourceType === "job")
                    &&
                    <CdsButton action="flat" status="primary" onClick={() => { navigateTo("/projects/" + props.graphMetadata.project_id + "/jobs/" + props.graphId) }}>Go to Job</CdsButton>
                }
                {
                    (props.drawMode === "draft" && props.resourceType === "job")
                    &&
                    <CompatibilityIndicator compatibleFramework={props.compatibleFramework} />
                }
            </div>

            <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "center", position: "relative" }}>
                <input
                    id="graph_title"
                    type="text"
                    style={{
                        color: "#52809c",
                        fontFamily: "Clarity City",
                        whiteSpace: "nowrap",
                        border: "none",
                        background: "transparent",
                        outline: "0",
                        fontSize: "min(20px,2vh)",
                        width: "33%",
                        textAlign: "center"
                    }}
                    contentEditable="true"
                    spellCheck="false"
                    maxLength={30}
                    onChange={onNameChange}
                    value={currentGraphName} />
                <div
                    style={{
                        position: "absolute",
                        color: "rgb(78 97 108)",
                        fontFamily: "Clarity City",
                        fontSize: "min(15px,1.5vh)",
                        top: "100%",
                        textTransform: "capitalize"
                    }}>
                    {props.resourceType} - {props.drawMode} mode
                </div>
            </div>
            <div style={{ display: "flex" }}>
                {
                    props.resourceType === "job" &&
                    <InstanceMenu currentGraphID={currentGraphID} currentGraphName={currentGraphName} setCurrentData={props.setCurrentData} setCurrentStatus={props.setCurrentStatus} addLogMessage={props.addLogMessage} />
                }
            </div>
        </div>
    );
}

export default MainMenu;
