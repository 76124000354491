import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import '@cds/core/range/register.js';
import Parameter from '../Parameter';


class TestParameters extends React.Component {

    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.open = false;
      }
    
    render () {
        return(
            <Parameter
            header="Test Parameters">
                <cds-checkbox>
                    <label>Accuracy score</label>
                    <input type="checkbox" id={this.id} name="text" className="nodrag" />
                </cds-checkbox>
            </Parameter>
        )
    }

}

export default TestParameters;
