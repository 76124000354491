


export default function NotFound() {

    return(
    <div style={{
        width: "100%", 
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "30px",
        color: "white"}}>
        <strong>404 Not Found</strong>
    </div>)
}