import React, { useCallback, useState } from 'react';
import { useReactFlow, useViewport } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import '@cds/core/card/register.js';
import { Icon } from '@iconify/react';

function LibraryCategory({ category, data, setOpen }) {
    const { addNodes, getViewport } = useReactFlow()

    function newNode(node_type) {
        const myuuid = uuidv4();
        addNodes(
            {
                id: myuuid,
                type: node_type,
                position: { x: 0, y: 0 },
                data: { dag_data: JSON.parse(JSON.stringify(data[node_type].dag_format)), node_data: JSON.parse(JSON.stringify(data[node_type].node_format)), status: "" }
            }
        );
        setOpen(false);
    }

    return (
        <div style={{ overflow: "visible" }}>
            <h2>{category}</h2>
            <div className='library-node-grid'>
                {Object.keys(data).map((singleNode, index) => (
                    <cds-card
                        key={"node_card_" + category + "_" + index}
                        onClick={() => newNode(singleNode)} cds-theme="dark" className="nodeCard">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon icon={data[singleNode]["node_format"].icon} />
                            <h3 style={{ paddingLeft: "10px" }}>{data[singleNode]["node_format"]["name"]}</h3>
                        </div>
                        <div className='card-content'>
                            <div className='card-description'>
                                {data[singleNode]["node_format"]["description"]}
                            </div>
                            <div className='card-io'>
                                {data[singleNode]["node_format"]["input"] && <div> Input: {data[singleNode]["node_format"]["input"].map((singleInput) => (singleInput["type"])).join()} </div>}
                                {data[singleNode]["node_format"]["output"] && <div> Output: {data[singleNode]["node_format"]["output"].map((singleOutput) => (singleOutput["type"])).join()} </div>}
                            </div>
                        </div>
                    </cds-card>
                ))}
            </div>
        </div>
    );
}

export default LibraryCategory;
