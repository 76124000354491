import React, { useState } from 'react';
import { useReactFlow, getRectOfNodes, getTransformForBounds } from 'reactflow';
import { CdsButton } from '@cds/react/button';
import { ClarityIcons, plusIcon } from '@cds/core/icon';

import { toJpeg } from 'html-to-image';
import ExportConversionJob from '../../../../../../services/Graph/Dag/ExportConversion';
import ExportConversionWorkflow from '../../../../../../services/Workflow/ExportConversion';

import { UpdateGraph } from '../../../../../../services/Graph/GraphService';


ClarityIcons.addIcons(plusIcon);

interface SaveMenuInterface {
    currentGraphName: string;
    currentGraphID: string;
    drawMode: DrawMode;
    resourceType: ResourceType;
    addLogMessage: (newMessage: LogMessage) => void;
    compatibleFramework: Record<LanguageName, Boolean>;
}
function SaveMenu({ currentGraphID, currentGraphName, drawMode, resourceType, addLogMessage, compatibleFramework }: SaveMenuInterface) {

    const imageWidth = 400;
    const imageHeight = 200;

    const { getNodes, toObject } = useReactFlow();

    const openSaveMenu = () => {
        addLogMessage(
            {
                "status": "pending",
                "message": "Saving graph..."
            }
        )
        const nodesBounds = getRectOfNodes(getNodes());
        const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.01, 2);
        const reactViewport = document.querySelector<HTMLElement>('.react-flow__viewport')!
        toJpeg(
            reactViewport,
            {
                quality: 0.95,
                backgroundColor: '#172329',
                width: imageWidth,
                height: imageHeight,
                style: {
                    width: imageWidth.toString(),
                    height: imageHeight.toString(),
                    transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
                },
            }
        ).then(
            (imageDataURL) => {

                const flow = toObject();
                let dag: any;
                if (resourceType === "job") {
                    dag = ExportConversionJob({ ...flow }, currentGraphID);
                }
                else {
                    dag = ExportConversionWorkflow({ ...flow }, currentGraphID)
                    console.log(dag)
                }


                UpdateGraph(
                    dag,
                    imageDataURL,
                    currentGraphName,
                    currentGraphID,
                    drawMode,
                    resourceType,
                    compatibleFramework
                )

                addLogMessage(
                    {
                        "status": "success",
                        "message": "Graph successfully saved."
                    }
                )
                return
            }
        ).catch(
            (error) => {
                addLogMessage(
                    {
                        "status": "error",
                        "message": "Couldn't save graph.\n" + error
                    }
                )
            }
        )
    }

    return (
        <div style={{ display: "flex" }}>
            <CdsButton action="flat" status="primary" onClick={openSaveMenu}>Save</CdsButton>
        </div>
    );
}

export default SaveMenu;
