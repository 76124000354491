import JoinNode from './Job/transformation/JoinNode.js';
import OrderNode from './Job/transformation/OrderNode.js';
import FilterNode from './Job/transformation/FilterNode.js';
import RenameNode from './Job/transformation/RenameNode.js';
import DropNode from './Job/transformation/DropNode.js';
import DistinctNode from './Job/transformation/DistinctNode.js';
import AggregationNode from './Job/transformation/AggregationNode.js';

import CommentNode from './documentation/CommentNode.js';

import DynamicNode from './DynamicNode';

export const jobNodeTypes = { 
    read_local: DynamicNode, 
    read_s3: DynamicNode,
    read_azure: DynamicNode,
    read_sql: DynamicNode,
    write_local: DynamicNode, 
    write_s3: DynamicNode,
    write_azure: DynamicNode,
    write_sql: DynamicNode,
    join: JoinNode,
    order: OrderNode,
    filter: FilterNode,
    rename: RenameNode,
    drop: DropNode,
    distinct: DistinctNode,
    aggregation: AggregationNode,
    random_forest_model: DynamicNode,
    predict: DynamicNode,
    import_model: DynamicNode,
    export_model: DynamicNode,
    comment: CommentNode };
  