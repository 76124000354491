import React, { 
  useCallback, 
  useRef, 
  ChangeEvent, 
  useEffect,
  useState
 } from 'react';
 
import { useNavigate } from 'react-router-dom';
import '@cds/core/card/register.js';
import '@cds/core/progress-circle/register.js';

import { Icon } from '@iconify/react';

import './templatelist.css'

import { LoadTemplateList } from '../../services/TemplateService';

export default function TemplateList( {FramePort} ) {

  const navigate = useNavigate();

  const [isLoading, setLoadingMode] = useState(true)
  const [templateList, setTemplateList] = useState([]);
  
  useEffect(() => {
    setLoadingMode(true)
    LoadTemplateList(FramePort)
        .then((response) => {
            setTemplateList(response)
            setLoadingMode(false)
        }).catch((err) => {
            console.log(err)
        })
  }, [FramePort]);

  const loadTemplate = (templateID) => {
    navigate('/template/' + templateID)
  }

  return(
    <>
    <div style={{ width: '100vw', height: '100vh' }}>
      <div className='startup_main'>
        <h2>Template List</h2>
        <h3>Select a template</h3>
          { 
          isLoading 
          && 
          <div className='loading_project'>
              <cds-progress-circle size="128" line="2"></cds-progress-circle>
          </div> 
          ||
          <div className='node-grid'>
            { templateList.map((singleTemplate, index) => (
                <cds-card
                    class="projectCard"
                    cds-theme="dark"
                    key={singleTemplate.name}
                    onClick={() => loadTemplate(singleTemplate.name)}
                >
                  <div className='template-title'>
                      <h3 style={{margin: "0", paddingLeft: "10px"}}>{singleTemplate.name}</h3>
                      <h4 style={{margin: "0", paddingRight: "10px"}}>v{singleTemplate.latest_version}</h4>
                  </div>
                </cds-card>
            ))}
          </div>
        }
      </div>
    </div>
    </>
  )
}