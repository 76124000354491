
function ImportConversionWorkflow(dag) {
    try {
        const flow = {
            "nodes": dag.metadata.nodes,
            "edges": dag.metadata.edges,
            "viewport": dag.metadata.viewport
        }
        for (var node of flow.nodes) {
            node.data = { node_data: dag.nodedata[node.id], dag_data: {} }
            for (var index in dag.dag.tasks) {
                if (dag.dag.tasks[index].name === node.id) {
                    node.data.dag_data = dag.dag.tasks[index]
                    if (node.data.dag_data.depends != undefined) {  // Reset the dependencies of the node
                        node.data.dag_data.depends = []
                    }
                }
            }
        }

        return flow
    }
    catch (e) {
        console.log(e)
    }
}

export default ImportConversionWorkflow;