import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import Parameter from '../Parameter';


class SQLOptions extends React.Component {

    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.onChangeURL = this.onChangeURL.bind(this);
        this.onChangePort = this.onChangePort.bind(this);
        this.onChangeDatabase = this.onChangeDatabase.bind(this);
        this.onChangeTable = this.onChangeTable.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.onChangeDatabaseName = this.onChangeDatabaseName.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.open = false
      }
    
    render () {
        return(
            <Parameter
            header="SQL Options">
                <cds-input layout="vertical">
                    <label>Database URL</label>
                    <input id={this.id} name="text" onChange={this.onChangeURL}  defaultValue={this.data.params.object.url} className="nodrag"/>
                </cds-input>
                <cds-input layout="vertical">
                    <label>Port</label>
                    <input id={this.id} name="text" onChange={this.onChangePort}  defaultValue={this.data.params.object.port} className="nodrag"/>
                </cds-input>
                <cds-input layout="vertical">
                    <label>Database name</label>
                    <input id={this.id} name="text" onChange={this.onChangeDatabase}  defaultValue={this.data.params.object.db} className="nodrag"/>
                </cds-input>
                <cds-input layout="vertical">
                    <label>Database schema name</label>
                    <input id={this.id} name="text" onChange={this.onChangeDatabaseName}  defaultValue={this.data.params.object.schema_db} className="nodrag"/>
                </cds-input>
                <cds-input layout="vertical">
                    <label>Table name</label>
                    <input id={this.id} name="text" onChange={this.onChangeTable}  defaultValue={this.data.params.object.table} className="nodrag"/>
                </cds-input>
                <cds-input layout="vertical">
                    <label>User</label>
                    <input id={this.id} name="text" onChange={this.onChangeUser}  defaultValue={this.data.params.object.user} className="nodrag"/>
                </cds-input>
                <cds-input layout="vertical">
                    <label>Password</label>
                    <input id={this.id} name="text" type="password" onChange={this.onChangePassword}  defaultValue={this.data.params.object.password} className="nodrag"/>
                </cds-input>
            </Parameter>
        )
    }

    onChangeURL(evt) {
        this.data.params.object.url = evt.target.value;
      }
    
    onChangePort(evt) {
    this.data.params.object.port = evt.target.value;
    }

    onChangeDatabase(evt) {
    this.data.params.object.db = evt.target.value;
    }

    onChangeDatabaseName(evt) {
    this.data.params.object.schema_db = evt.target.value;
    }

    onChangeTable(evt) {
    this.data.params.object.table = evt.target.value;
    }

    onChangeUser(evt) {
    this.data.params.object.user = evt.target.value;
    }

    onChangePassword(evt) {
    this.data.params.object.password = evt.target.value;
    }
}

export default SQLOptions;
