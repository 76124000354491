import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';

class Checkbox extends React.Component {

  constructor({label, data, handleChange}) {
    super()
    this.label = label;
    this.data = data;
    this.checked = data;
    this.handleChange = handleChange;
    this.handleDataChange = this.handleDataChange.bind(this);
  }
  
  render() {
    return (
        <cds-checkbox>
            <label>{this.label}</label>
            <input type="checkbox" onChange={this.handleDataChange} checked={this.checked} />
        </cds-checkbox>
    );
  }

  handleDataChange(e) {
    this.handleChange(this.data)
    this.checked = !this.checked
    this.forceUpdate();
  }

}

export default Checkbox;
