import connectionInfo from "./PortCommunication";



export function navigateTo( pagePath) {
    connectionInfo.port.postMessage(
        {
            "type": "navigate",
            "data": {
                "path": pagePath
            }
        }, 
    );
}

export function LoadWorkspaceList() {

    let communicationChannel = new MessageChannel();

    return new Promise((resolve, reject) => {
        communicationChannel.port1.onmessage = (event) => {
            if (event.data.data.result === "success") {
                resolve(event.data.data.content)
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "workspace_list"
            }, [communicationChannel.port2]);
        }
    )
}

export function AddFileToWorkspace( language, dag, workspaceID, workspacePath ) {

    let communicationChannel = new MessageChannel();

    return new Promise((resolve, reject) => {
        communicationChannel.port1.onmessage = (event) => {
            if (event.data.data.result === "success") {
                resolve()
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "add_code_to_workspace",
                "data": {
                    "language" : language,
                    "dag": dag,
                    "workspace_id": workspaceID,
                    "workspace_path": workspacePath
                }
            }, [communicationChannel.port2]);
        }
    )
}
