import React from 'react';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, timesIcon } from '@cds/core/icon';
import Parameter from '../options/Parameter';
import Node from '../../components/Node';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';

ClarityIcons.addIcons(timesIcon);

class AggregationNode extends React.Component {

  constructor(props) {
    super(props);
    this.id = props.id;
    this.data = props.data;
    this.isConnectable = props.isConnectable;
    this.handleConditionChange = this.handleConditionChange.bind(this);
    this.handleConditionAdd = this.handleConditionAdd.bind(this);
    this.handleConditionRemove = this.handleConditionRemove.bind(this);
    this.handleColumnChange = this.handleColumnChange.bind(this);
    this.handleColumnAdd = this.handleColumnAdd.bind(this);
    this.handleColumnRemove = this.handleColumnRemove.bind(this);
  }

  render() {
    return (
      <Node
        id={this.id}
        nodeId={this.id}
        nodeClass="aggregation-node"
        nodeData={this.data.node_data}
        nodeStatus={this.props.data.status}
        isConnectable={this.isConnectable}
        exportHandleList={[
          {
            type: "Dataset", 
            name: ""
          }
        ]}
        importHandleList={[
          {
            type: "Dataset", 
            name: ""
          }
        ]
      }>
         <Parameter
          header="Group columns">
              {this.data.dag_data.params.group_columns.map((singleColumn, index) => (
                <div>
                  <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <cds-input layout="vertical">
                      <label>Column name</label>
                      <input 
                        id="text" 
                        name="column" 
                        onChange={(e) => this.handleColumnChange(e, index)} 
                        className="nodrag"
                        value={singleColumn} 
                        style={{width: '60px'}}
                        />
                    </cds-input>
                    </div> 
                    { this.data.dag_data.params.group_columns.length > 1 &&
                      <CdsIcon className="custom-button" size="icon" shape="times" onClick={() => this.handleColumnRemove(index)}></CdsIcon>}               
                  </div>
                  {this.data.dag_data.params.group_columns.length - 1 === index && this.data.dag_data.params.group_columns.length < 5 &&
                  <div className='custom-button' onClick={this.handleColumnAdd}>
                    Add Column
                  </div>
                  }      
                </div>
              ))}
         </Parameter>
         <Parameter
          header="Aggregations rules">
            {this.data.dag_data.params.aggregations.map((singleCondition, index) => (
                <div>
                  <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                      <cds-input layout="vertical">
                      <label>Column name</label>
                      <input 
                        id="text" 
                        name="column" 
                        onChange={(e) => this.handleConditionChange(e, index)} 
                        className="nodrag"
                        value={singleCondition.column} 
                        style={{width: '60px'}}
                        />
                      </cds-input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                      <cds-select layout="vertical">
                          <label>Type</label>
                          <select 
                            name="type"
                            onChange={(e) => this.handleConditionChange(e, index)}
                            className="nodrag" 
                            value={singleCondition.type} 
                            style={{width: '80px', fontSize: '10px'}}>
                              <optgroup style={{fontSize: '20px'}}>
                                <option value="max">Maximum</option>
                                <option value="min">Minimum</option>
                                <option value="mean">Mean</option>
                                <option value="count">Count</option>
                                <option value="sum">Sum</option>
                              </optgroup>
                          </select>
                      </cds-select>
                    </div>
                    { this.data.dag_data.params.aggregations.length > 1 &&
                      <CdsIcon className="custom-button" size="icon" shape="times" onClick={() => this.handleConditionRemove(index)}></CdsIcon>}
                  </div>
                  {this.data.dag_data.params.aggregations.length - 1 === index && this.data.dag_data.params.aggregations.length < 5 &&
                  <div className='custom-button' onClick={this.handleConditionAdd}>
                    Add Condition
                  </div>
                  }
                </div>
              ))}
         </Parameter>
      </Node>
    );
  }

  handleConditionChange = (e, index) => {
    const {name, value} = e.target;
    const list = [...this.data.dag_data.params.aggregations];
    list[index][name] = value;
    this.data.dag_data.params.aggregations = list;
    this.forceUpdate();
  }

  handleConditionAdd = () => {
    this.data.dag_data.params.aggregations = [...this.data.dag_data.params.aggregations, {type : "", column : ""}]
    this.forceUpdate();
  }

  handleConditionRemove = (index) => {
    const list = [...this.data.dag_data.params.aggregations];
    list.splice(index, 1);
    this.data.dag_data.params.aggregations = list;
    this.forceUpdate();
  }

  handleColumnChange = (e, index) => {
    const {name, value} = e.target;
    const list = [...this.data.dag_data.params.group_columns];
    list[index] = value;
    this.data.dag_data.params.group_columns = list;
    this.forceUpdate();
  }

  handleColumnAdd = () => {
    this.data.dag_data.params.group_columns = [...this.data.dag_data.params.group_columns, ""]
    this.forceUpdate();
  }

  handleColumnRemove = (index) => {
    const list = [...this.data.dag_data.params.group_columns];
    list.splice(index, 1);
    this.data.dag_data.params.group_columns = list;
    this.forceUpdate();
  }

}

export default AggregationNode;
