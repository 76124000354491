import { v4 as uuidv4 } from 'uuid';
import ExportFilter from './ConvertFilter';

interface Task {
    id: string;
    type: string;
    dependency: any[];
    params: {};
}

function ExportConversionJob(flowRef, id) {
    const flow = JSON.parse(JSON.stringify(flowRef)); //deep copy to prevent reference modification
    const tasks: Task[] = []
    const dag = {
        dag: {
            "id": id,
            "tasks": tasks
        },
        metadata: {
            nodes: flow.nodes,
            edges: flow.edges,
            viewport: flow.viewport
        },
        nodedata: {
        }
    }
    for (var node of flow.nodes) {
        const dagData = { ...node.data.dag_data }
        const nodeData = { ...node.data.node_data }
        dagData.id = node.id
        if (dagData.type == "filter") {
            var params = ExportFilter({ ...dagData.params });
            dagData.params = params;
        }
        if (dagData.type) {
            dag.dag.tasks.push(dagData)
        }
        dag.nodedata[node.id] = nodeData
    }
    for (var edge of flow.edges) {
        for (var index in dag.dag.tasks) {
            if (dag.dag.tasks[index].id == edge.target) {
                // Insert the dependency at the good position
                dag.dag.tasks[index].dependency.splice(
                    parseInt(edge.targetHandle.split('_').pop()), 0, edge.source);
            }
        }
    }
    for (var index in dag.metadata.nodes) {
        delete dag.metadata.nodes[index]['data'];
    }
    return dag
}

export default ExportConversionJob;