import connectionInfo from "./PortCommunication";

export function LoadTemplateList() {

    let communicationChannel = new MessageChannel();

    return new Promise((resolve, reject) => {
        communicationChannel.port1.onmessage = (event) => {
            if (event.data.data.result === "success") {
                resolve(event.data.data.content)
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "template_list",
                "data": {
                }
            }, [communicationChannel.port2]);
        }
    )
}

export function LoadTemplateVersions( templateName ) {

    let communicationChannel = new MessageChannel();

    return new Promise((resolve, reject) => {
        communicationChannel.port1.onmessage = (event) => {
            if (event.data.data.result === "success") {
                resolve(event.data.data.content)
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "template_versions",
                "data": {
                    "template_name": templateName
                }
            }, [communicationChannel.port2]);
        }
    )
}

export function LoadTemplateMetadata( templateName, templateVersion ) {

    let communicationChannel = new MessageChannel();

    return new Promise((resolve, reject) => {
        communicationChannel.port1.onmessage = (event) => {
            if (event.data.data.result === "success") {
                resolve(event.data.data.content)
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "template_metadata",
                "data": {
                    "template_name": templateName,
                    "template_version": templateVersion
                }
            }, [communicationChannel.port2]);
        }
    )
}

export function GenerateTemplate( templateName, templateVersion, templateBody ) {

    let communicationChannel = new MessageChannel();

    return new Promise((resolve, reject) => {
        communicationChannel.port1.onmessage = (event) => {
            if (event.data.data.result === "success") {
                resolve(event.data.data.content)
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "generate_template",
                "data": {
                    "template_name": templateName,
                    "template_version": templateVersion,
                    "template_body": templateBody
                }
            }, [communicationChannel.port2]);
        }
    )
}