import React from 'react';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, timesIcon } from '@cds/core/icon';
import Parameter from '../options/Parameter';
import Node from '../../components/Node';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';

ClarityIcons.addIcons(timesIcon);

class RenameNode extends React.Component {

  constructor(props) {
    super(props);
    this.id = props.id;
    this.data = props.data;
    this.isConnectable = props.isConnectable;
    this.handleNameChange = this.handleNameChange.bind(this);
  }

  render() {
    return (
      <Node
        id={this.id}
        nodeId={this.id}
        header="Rename"
        nodeClass="rename-node"
        nodeData={this.data.node_data}
        nodeStatus={this.props.data.status}
        isConnectable={this.isConnectable}
        exportHandleList={[
          {
            type: "Dataset", 
            name: ""
          }
        ]}
        importHandleList={[
          {
            type: "Dataset", 
            name: ""
          }
        ]
      }>
          <Parameter
            header="Rename Parameters">    
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
              <cds-input layout="vertical">
                <label>Old name</label>
                <input 
                  id="text" 
                  name="old_name"
                  onChange={(e) => this.handleNameChange(e)}
                  defaultValue={this.data.dag_data.params.old_name}
                  className="nodrag"
                  style={{width: '60px'}}
                  />
              </cds-input>
              <cds-input layout="vertical">
              <label>New name</label>
                <input 
                  id="text" 
                  name="new_name"
                  onChange={(e) => this.handleNameChange(e)}
                  defaultValue={this.data.dag_data.params.new_name}
                  className="nodrag" 
                  style={{width: '60px'}}
                  />
              </cds-input>
            </div>
        </Parameter>
      </Node>
    );
  }

  handleNameChange(e) {
    const {name, value} = e.target;
    this.data.dag_data.params[name] = value;
  }

}

export default RenameNode;
