import React, { useState } from 'react';
import { useReactFlow, getRectOfNodes, getTransformForBounds  } from 'reactflow';
import { CdsButton } from '@cds/react/button';
import { ClarityIcons, plusIcon } from '@cds/core/icon';
import '@cds/core/table/table.min.css';

interface DataTableInterface {
    currentData: any;
    selectedNode: string[];
}
function DataTable({ currentData, selectedNode}: DataTableInterface) {

    if (selectedNode.length == 0) {
        return (
            <div style={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                No node selected
            </div>
        )
    }

    if ((currentData !== null && currentData[selectedNode[0]] !== undefined)) {
        return(
            <div style={{display: "flex", flexDirection: "column", WebkitBoxFlex: "1", flexGrow: "1", overflow: "hidden"}}>
                <div style={{overflowY: "auto", WebkitBoxFlex: "1", flexGrow: "1", position: "relative"}}>
                    <div style={{position: "absolute", inset: "0px"}}>
                    <div style={{ overflowX: "auto"}}>
                        <table cds-table="border:all" cds-theme="dark">  
                        <thead>
                            <tr>
                            {Object.keys(currentData[selectedNode[0]]).map((item, i) => (
                                <th>{item}</th>
                            ))
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentData[selectedNode[0]][Object.keys(currentData[selectedNode[0]])[0]].map((item, i) => (
                                    <tr key={i}>
                                    {
                                        Object.keys(currentData[selectedNode[0]]).map((item_key, j) => (
                                            <td>{currentData[selectedNode[0]][item_key][i]}</td>
                                        ))
                                    }
                                    </tr> 
                                ))
                            }
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div style={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
            Nothing to show yet
        </div>
    )


}
      
export default DataTable;
