import React from 'react';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, timesIcon } from '@cds/core/icon';

import '@cds/core/select/register.js';
import '@cds/core/button/register.js';

import Parameter from './Parameter';

ClarityIcons.addIcons(timesIcon);

class DataStructure extends React.Component {


    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.handleStructureColumnAdd = this.handleStructureColumnAdd.bind(this);
        this.handleStructureColumnRemove = this.handleStructureColumnRemove.bind(this);
        this.handleStructureColumnChange = this.handleStructureColumnChange.bind(this);   
      }
    
    render () {
        return(
          <Parameter
          header="Data Structure">
                <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                  <cds-button size="sm">Infer schema</cds-button>
                </div>
                {this.data.params.structure.map((singleColumn, index) => (
                <div key={"data-structure-" + this.id + "-" + index}>
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                    <cds-input layout="vertical">
                      <label>Column name</label>
                      <input 
                        id="text" 
                        name="name" 
                        className="nodrag"
                        value={singleColumn.name} 
                        onChange={(e) => this.handleStructureColumnChange(e, index)} 
                        style={{width: '60px'}}
                      />
                    </cds-input>
                        <cds-select layout="vertical">
                          <label>Column type</label>
                          <select
                          className="nodrag" 
                          name="type"
                          value={singleColumn.type}
                          onChange={(e) => this.handleStructureColumnChange(e, index)}
                          style={{width: '50px', fontSize: '9px'}}>
                            <option value="int32">Integer (32 bits)</option>
                            <option value="int64">Integer (64 bits)</option>
                            <option value="str">String</option>
                            <option value="bool">Boolean</option>
                            <option value="double">Float</option>
                          </select>
                        </cds-select>
                    { this.data.params.structure.length > 1 &&
                        <CdsIcon className="custom-button" size="18" shape="times" onClick={() => this.handleStructureColumnRemove(index)}></CdsIcon>}
                    </div>
                </div>
                ))}
                {this.data.params.structure.length < 15 &&
                    <div className='custom-button' onClick={this.handleStructureColumnAdd}>
                      Add Column
                    </div>
                }
          </Parameter>
        )
    }

    handleStructureColumnChange(e, index) {
        const {name, value} = e.target;
        const list = [...this.data.params.structure];
        list[index][name] = value;
        this.data.params.structure = list;
        this.forceUpdate();
      }
    
      handleStructureColumnAdd() {
        this.data.params.structure = [...this.data.params.structure, {"name": "", "type": "int32"}];
        this.forceUpdate();
      }
    
      handleStructureColumnRemove(index) {
        const list = [...this.data.params.structure];
        list.splice(index, 1);
        this.data.params.structure = list;
        this.forceUpdate();
      }
}

export default DataStructure;
