import React, { useEffect, useState } from 'react';
import { useReactFlow, useOnSelectionChange } from 'reactflow';
import { useParams, useNavigate } from 'react-router-dom';
import ImportConversionJob from '../../services/Graph/Dag/ImportConversion';
import ImportConversionWorkflow from '../../services/Workflow/ImportConversion';
import MainMenu from './components/MainMenu/MainMenu';
import DrawInterface from './components/DrawInterface/DrawInterface';
import PreviewTab from './components/PreviewTab/PreviewTab';
import { LoadGraph } from '../../services/Graph/GraphService';
import LoadingScreen from './components/LoadingScreen/LoadingScreen.js';

import { VerifyDag } from '../../services/Graph/Dag/DagService';
import ExportConversionJob from '../../services/Graph/Dag/ExportConversion';

interface DrawInterface {
  FramePort: MessagePort | undefined;
}
export default function Draw({ FramePort }: DrawInterface) {

  const navigate = useNavigate();

  const { setNodes, setEdges, setViewport, addNodes, toObject } = useReactFlow();
  const [graphName, setgraphName] = useState<string>("Unknown")
  const [isLoading, setLoading] = useState<boolean>(true)
  const [graphMetadata, setGraphMetadata] = useState({})
  const [currentData, setCurrentData] = useState(null)
  const [currentStatus, setCurrentStatus] = useState<NodeStatus>("none")
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [selectedEdges, setSelectedEdges] = useState<string[]>([]);
  const [logList, setLogList] = useState<LogMessage[]>([]);

  const [compatibleFramework, setCompatibility] = useState<Record<LanguageName, Boolean>>(
    {
      "pyspark": true,
      "java": true,
      "flink": true,
      "beam": true,
      "pandas": true,
      "dask": true
    }
  )

  let params = useParams();

  let graphId = params.graphId as string;
  let resourceType = params.resourceType as ResourceType;
  let drawMode = params.drawMode as DrawMode;

  useEffect(() => {
    if (FramePort instanceof MessagePort) {
      loadProject();
      initLog(resourceType);
      verifyDag();
    }
  }, [FramePort, graphId])

  useEffect(() => {
    if (drawMode == "draft" && resourceType == "job") {
      const interval = setInterval(() => verifyDag(), 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      setSelectedNodes(nodes.map((node) => node.id));
      setSelectedEdges(edges.map((edge) => edge.id));
    },
  });

  const verifyDag = () => {
    const flow = toObject();
    const dag = ExportConversionJob({ ...flow }, graphId);
    VerifyDag(dag.dag).then((data) => {
      // Change compatible framework
      const newCompatibleFramework = {
        "pyspark": false,
        "java": false,
        "flink": false,
        "beam": false,
        "pandas": false,
        "dask": false
      }
      for (const element of data[1]) {
        newCompatibleFramework[element] = true
      }
      setCompatibility(newCompatibleFramework)
    })
  }


  const initLog = (resourceType: ResourceType) => {
    setLogList([])
    if (resourceType === "job") {
      addLogMessage(
        {
          "status": "info",
          "message": "Welcome to the Low-Code Engine!\nYou can start adding tasks to your graph using the 'Add node' button. You can preview your graph using the 'Run' button on the top right corner."
        }
      );
    } else if (resourceType === "workflow") {
      addLogMessage(
        {
          "status": "info",
          "message": "Welcome to the Workflow Engine!\nYou can start adding tasks to your graph using the 'Add node' button."
        }
      );
    }
  }

  const addLogMessage = (newMessage: LogMessage) => {
    let copyLogList = logList
    var d = new Date();
    var n = d.toLocaleTimeString();
    newMessage.time = n
    setLogList(copyLogList => [...copyLogList, newMessage]);
  }

  const loadProject = () => {
    switch (resourceType) {
      case 'job':
        loadJobProject();
        break;
      case 'workflow':
        loadWorkflowProject();
        break;
      default:
        navigate('/notfound')
    }
  }

  const loadWorkflowProject = () => {
    switch (params.drawMode) {
      case 'draft':
        LoadGraph(graphId, 'workflow', 'draft')
          .then((data) => {
            const flow = ImportConversionWorkflow(data.metadata.flow);
            if (flow) {
              console.log("graph is loading")
              setLoading(false);
              addNodes(flow.nodes || []);
              setEdges(flow.edges || []);
              setgraphName(data.name);
              return;
            }
          }).catch((err) => {
            if (err != "loading") {
              navigate('/notfound')
            }
          });
        break
      case 'project':
        LoadGraph(graphId, 'workflow', 'project')
          .then((data) => {
            const flow = ImportConversionWorkflow(data.metadata.graph.metadata.flow);
            if (flow) {
              console.log("graph is loading")
              setLoading(false);
              addNodes(flow.nodes || []);
              setEdges(flow.edges || []);
              setgraphName(data.name);
              setGraphMetadata({
                "project_id": data.project_id
              })
              return;
            }
          }).catch((err) => {
            if (err != "loading") {
              navigate('/notfound')
            }
          });
        break
    }
  }

  const loadJobProject = () => {
    switch (params.drawMode) {
      case 'draft':
        LoadGraph(graphId, 'job', 'draft')
          .then((data) => {
            const flow = ImportConversionJob(data.metadata.flow);
            if (flow) {
              console.log("graph is loading")
              setLoading(false);
              addNodes(flow.nodes || []);
              setEdges(flow.edges || []);
              setgraphName(data.name);
              return;
            }
          }).catch((err) => {
            if (err != "loading") {
              navigate('/notfound')
            }
          });
        break;
      case 'project':
        LoadGraph(graphId, 'job', 'project')
          .then((data) => {
            const flow = ImportConversionJob(data.metadata.graph.metadata.flow);
            if (flow) {
              console.log("graph is loading")
              setLoading(false);
              addNodes(flow.nodes || []);
              setEdges(flow.edges || []);
              setgraphName(data.name);
              setGraphMetadata({
                "project_id": data.project_id
              })
              return;
            }
          }).catch((err) => {
            if (err != "loading") {
              navigate('/notfound')
            }
          });
        break;
      default:
        navigate('/notfound')
    }
  }

  return (
    <>
      {isLoading &&
        <LoadingScreen /> ||
        <div style={{ width: '100vw', height: '100vh' }}>
          <MainMenu graphId={graphId} graphName={graphName} resourceType={resourceType} drawMode={drawMode} graphMetadata={graphMetadata} setCurrentData={setCurrentData} setCurrentStatus={setCurrentStatus} addLogMessage={addLogMessage} compatibleFramework={compatibleFramework} verifyDag={verifyDag} />
          <div style={{ width: '100vw', height: '95%', maxHeight: '95%', display: "flex", flexDirection: "column", overflow: "hidden" }}>
            <div style={{ flexShrink: "0", minHeight: "25%", maxHeight: "100%", height: "80%", resize: "vertical", overflow: "auto" }}>
              <DrawInterface resourceType={resourceType} currentStatus={currentStatus} />
            </div>
            <PreviewTab currentData={currentData} selectedNode={selectedNodes} logList={logList} />
          </div>
        </div>
      }
    </>
  );
}
