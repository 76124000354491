import React, { useState } from 'react';
import { useReactFlow, getRectOfNodes, getTransformForBounds  } from 'reactflow';
import { CdsButton } from '@cds/react/button';
import { ClarityIcons, plusIcon } from '@cds/core/icon';
import { Icon } from '@iconify/react';
import '@cds/core/table/table.min.css';

interface LogPanelInterface {
    logList: LogMessage[];
}
function LogPanel({ logList }:LogPanelInterface) {

    const iconType = {
        "pending": "material-symbols:pending-outline",
        "info": "material-symbols:info-outline",
        "error": "material-symbols:error-outline",
        "warning": "material-symbols:warning-outline",
        "success": "material-symbols:check-circle-outline",
        "message": "material-symbols:keyboard-arrow-right"
    }

    const colorType = {
        "pending": "#fff2f0",
        "info": "#0079ad",
        "error": "#e02200",
        "warning": "#ffb92e",
        "success": "#42810e",
        "message": "#fff2f0"    
    }

    return (
        <>
            {
                logList.map((item, i) => (
                    <div
                        key={'log_message_' + i}
                        style={{
                        display: "flex",
                        padding: "5px",
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "#82a1b5",
                        width: "100%"}}>
                        <div>
                            <Icon
                                style={{
                                    fontSize: "1.2em",
                                    color: colorType[item.status]
                                }}
                                icon={iconType[item.status]} />
                        </div>
                        <div style={{
                            marginLeft: "5px",
                            width: "100%"
                        }}>
                            <div style={{ 
                                fontSize: "0.8em",
                                color: "#cbd4d8",
                                display: "flex",
                                alignItems: "center"}}>
                                <pre style={{
                                    whiteSpace: "pre-wrap",
                                    margin: "0"
                                }}>
                                    {item.message}
                                </pre>  
                            </div>
                            <div style={{
                                fontSize: "0.6em",
                                color: "#858c8f",
                                width: "100%",
                                textAlign: "right"
                            }}>
                                {item.time}
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )

}

export default LogPanel