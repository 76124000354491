import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import Parameter from '../Parameter';

import Checkbox from '../../../components/inputs/Checkbox';

class ModelObject extends React.Component {

    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.onChangePath = this.onChangePath.bind(this);
        this.onChangeSeparator = this.onChangeSeparator.bind(this);
        this.onChangeCompression = this.onChangeCompression.bind(this);
        this.onChangeMode = this.onChangeMode.bind(this);
        this.toggle = this.toggle.bind(this)
        this.open = false
        if (this.data.params.object.type == "") {
          this.currentPath = this.data.params.object.path
        } else {
          this.currentPath = this.data.params.object.path + "." + this.data.params.object.type 
        }
        
      }
    
    render () {
        return(
          <Parameter
            header="Model Parameters">
                    <cds-input layout="vertical">
                      <label>Model path</label>
                      <input placeholder="model.pickle" id={this.id} name="text" onChange={this.onChangePath}  value={this.currentPath} className="nodrag" required />
                    </cds-input>
          </Parameter>
        )
    }

    toggle(evt) {
      this.open = !this.open
      this.forceUpdate()
    }

    onChangePath(evt) {
        if ( evt.target.value.includes('.')) {
          const input = evt.target.value.split('.')
          this.data.params.object.type = input.pop().toLowerCase();
          this.data.params.object.path = input.join();
          
        } else {
          this.data.params.object.path = evt.target.value
        }
        this.currentPath = evt.target.value;
        this.forceUpdate();
      }
    
    onChangeSeparator(evt) {
      this.data.params.object.separator = evt.target.value;
      this.forceUpdate();
    }

    onChangeCompression(evt) {
      this.data.params.object.compression = evt.target.value;
      this.forceUpdate();
    }

    onChangeMode(evt) {
      this.data.params.object.mode = evt.target.value;
      this.forceUpdate();
    }

}

export default ModelObject;
