import React, { useState } from 'react';
import { CdsButton } from '@cds/react/button';
import { ClarityIcons, plusIcon } from '@cds/core/icon';
import '@cds/core/card/register.js';


import { useNavigate  } from "react-router-dom";

ClarityIcons.addIcons(plusIcon);

function ProjectButton() {
    const navigate = useNavigate();

    return (
        <div style={{display: "flex"}}> 
            <CdsButton action="flat" status="primary" onClick={() => {navigate("/")}}>Home</CdsButton>
        </div>
    );
    }

export default ProjectButton;
