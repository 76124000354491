import React, { useState, useContext, useEffect } from 'react';
import { ClarityIcons, plusIcon } from '@cds/core/icon';
import '@cds/core/card/register.js';
import '@cds/core/progress-circle/register.js';
import { Icon } from '@iconify/react';
import { LoadWorkspaceList } from '../../../../../../../services/FrameUtils';

import './WorkspaceList.css'

ClarityIcons.addIcons(plusIcon);

export default function WorkspaceList({setWorkspaceSelected}) {

    const [isLoading, setLoadingMode] = useState(true)
    const [workspaceList, setWorkspaceList] = useState([])

    useEffect(() => {
        setLoadingMode(true)
        LoadWorkspaceList()
            .then((response) => {
                setWorkspaceList(response)
                setLoadingMode(false)
            }).catch((err) => {
                console.log(err)
            })
        }, []);

    return (
        <>
            { isLoading && 
                <div className='loading_project'>
                    <cds-progress-circle size="128" line="2"></cds-progress-circle>
                </div> || 
                <div className="workspace_list">
                    { workspaceList.map((singleWorkspace, index) => (
                        <>
                            {
                                ["vscode", "jupyter"].includes(singleWorkspace.type) &&
                                <cds-card class="workspaceCard" cds-theme="dark" onClick={() => setWorkspaceSelected(singleWorkspace.id)}>
                                <div class="workspace-card-content">
                                    <Icon
                                        style={{
                                            fontSize: "3em"
                                        }}
                                        icon={
                                            singleWorkspace.type === "vscode" &&
                                            "akar-icons:vscode-fill" ||
                                            "simple-icons:jupyter"} />
                                    <div>
                                        {singleWorkspace.name}
                                    </div>
                                    <div>
                                        {singleWorkspace.id}
                                    </div>
                                </div>
                            </cds-card>
                            }
                        </>
                    ))
                    }
                </div>
            }
        </>
    )
}