import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';

import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, timesIcon } from '@cds/core/icon';


ClarityIcons.addIcons(timesIcon);

class StringList extends React.Component {

  constructor({main_label, condition_label, max_length, data, handleDataChange}) {
    super()
    this.main_label = main_label;
    this.condition_label = condition_label;
    this.max_length = max_length;
    if (data) {
        this.data = data;
    } else {
        this.data = [""]
    }
    this.handleDataChange = handleDataChange;
    this.handleColumnChange = this.handleColumnChange.bind(this);
    this.handleColumnAdd = this.handleColumnAdd.bind(this);
    this.handleColumnRemove = this.handleColumnRemove.bind(this);
  }
  
  render() {
    return (
        <div style={{display: 'flex', flexDirection: "column"}}>
            <div style={{paddingBottom: "10px"}}>{this.main_label}</div>
                {this.data.map((singleCondition, index) => (
                  <div style={{paddingBottom: "10px"}}>
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                        <cds-input layout="vertical">
                        <label>{this.condition_label}</label>
                        <input 
                        id="text" 
                        name="drop_col"
                        onChange={(e) => this.handleColumnChange(e, index)}
                        value={singleCondition} 
                        className="nodrag" 
                        style={{width: '60px'}}
                        />
                    </cds-input>
                    { this.data.length > 1 &&
                      <CdsIcon className="custom-button" size="icon" shape="times" onClick={() => this.handleColumnRemove(index)}></CdsIcon>}
                    </div>
                  </div>
                ))}
                {this.data.length < this.max_length &&
                  <div className='custom-button' onClick={this.handleColumnAdd}>
                    Add Condition
                  </div>
                }
        </div>
    );
  }

  handleColumnChange(e, index) {
    const {name, value} = e.target;
    const list = [...this.data];
    list[index] = value;
    this.data = list;
    this.handleDataChange(this.data);
    this.forceUpdate();
  }

  handleColumnAdd() {
    this.data = [...this.data, ""];
    this.handleDataChange(this.data);
    this.forceUpdate();
  }

  handleColumnRemove(index) {
    const list = [...this.data];
    list.splice(index, 1);
    this.data = list;
    this.handleDataChange(this.data);
    this.forceUpdate();
  }

}

export default StringList;
