import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import Parameter from '../Parameter';

class AzureOptions extends React.Component {

    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.onChangeStorageName = this.onChangeStorageName.bind(this);
        this.onChangeContainer = this.onChangeContainer.bind(this);
        this.onChangeSASToken = this.onChangeSASToken.bind(this);
        this.open = false
      }
    
    render () {
        return(
            <Parameter
            header="Azure Options">
                <cds-input layout="vertical">
                    <label>Storage name</label>
                    <input id={this.id + "-storage-name"} name="text" onChange={this.onChangeStorageName}  value={this.data.params.connector.options.storage_name} className="nodrag" />
                </cds-input>
                <cds-input layout="vertical">
                    <label>Container</label>
                    <input id={this.id + "-container"} name="text" onChange={this.onChangeContainer}  value={this.data.params.connector.options.container} className="nodrag" />
                </cds-input>
                <cds-input layout="vertical">
                    <label>SAS token</label>
                    <input id={this.id + "-token"} name="text" type="password" onChange={this.onChangeSASToken}  value={this.data.params.connector.options.sas_token} className="nodrag" />
                </cds-input>
            </Parameter>
        )
    }

    onChangeStorageName(evt) {
        this.data.params.connector.options.storage_name = evt.target.value;
        this.forceUpdate()
      }
    
    onChangeContainer(evt) {
        this.data.params.connector.options.container = evt.target.value;
        this.forceUpdate()
    }

    onChangeSASToken(evt) {
        this.data.params.connector.options.sas_token = evt.target.value;
        this.forceUpdate()
    }

}

export default AzureOptions;
