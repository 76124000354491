import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import '@cds/core/range/register.js';
import Parameter from '../Parameter';

import Range from '../../../components/inputs/Range';
import StringList from '../../../components/inputs/StringList';


class TrainParameters extends React.Component {

    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.open = false;
      }
    
    render () {
        return(
            <Parameter
            header="Train Parameters">
                {/* <cds-checkbox>
                    <label>Shuffle</label>
                    <input type="checkbox" id={this.id} name="text" className="nodrag" />
                </cds-checkbox> */}
                <Range 
                    label = "Test Size"
                    min = {0}
                    max = {1}
                    step = {0.01}
                    data = {this.data.params.test_size}
                    handleChange = {(e) => {this.data.params.test_size = e}}
                />
                <cds-input layout="vertical">
                    <label>Predict Column</label>
                    <input placeholder="y_column" id={this.id} name="text" onChange={(e) => {this.data.params.predict_column = e.target.value; this.forceUpdate()}}  value={this.data.params.predict_column} className="nodrag" required />
                </cds-input>
                <StringList 
                    main_label = "Feature columns"
                    condition_label = "Column name"
                    max_length = {10}
                    data={this.data.params.features_column}
                    handleDataChange= {(e) => {this.data.params.features_column = e}}
                />
            </Parameter>
        )
    }

}

export default TrainParameters;
