import { Icon } from '@iconify/react';
import { CdsButton } from '@cds/react/button';
import "./projectNavigation.css"
import { useState, useRef } from 'react';
import MyGraphList from './components/myGraphList';
import JobGraphList from './components/jobGraphList';
import WorkflowGraphList from './components/workflowGraphList';
import AllGraphList from './components/allGraphList';
import { CreateGraph } from '../../../../services/Graph/GraphService';

export default function ProjectNavigation({ openDeleteModal, FramePort, myGraphList, setMyGraphList, allGraphList, setAllGraphList, jobGraphList, setJobGraphList, workflowGraphList, setWorkflowGraphList }) {

    const [activePanel, setActivePanel] = useState("draft-me")
    const [refreshValue, setRefreshValue] = useState(true)
    const inputFile = useRef(null)

    const onUploadClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const onFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        let reader = new FileReader();

        reader.readAsText(file);

        reader.onload = function () {
            const flowJSON = JSON.parse(reader.result);
            CreateGraph("Imported Project", flowJSON)
                .then((newID) => {
                    setRefreshValue(!refreshValue);
                })
        };

        reader.onerror = function () {
            console.log(reader.error);
        };
    }

    return (
        <>
            <input type='file' accept="application/json" id='file' ref={inputFile} style={{ display: 'none' }} onChange={onFileChange} />
            <div className='menu-bar'>
                <nav className="cds-tabs-mock">
                    <button className={activePanel === "draft-me" ? 'active' : ''} onClick={() => setActivePanel("draft-me")}><Icon icon="mdi:paper-outline" /> My draft projects</button>
                    <button className={activePanel === "draft-all" ? 'active' : ''} onClick={() => setActivePanel("draft-all")}><Icon icon="mdi:people-outline" /> All draft projects</button>
                    <button className={activePanel === "job" ? 'active' : ''} onClick={() => setActivePanel("job")}><Icon icon="carbon:batch-job" /> Job projects</button>
                    <button className={activePanel === "workflow" ? 'active' : ''} onClick={() => setActivePanel("workflow")}><Icon icon="carbon:batch-job" /> Workflow projects</button>
                </nav>
                {activePanel === "draft-me" &&
                    <CdsButton
                        action="outline"
                        style={{ marginLeft: '1%' }}
                        onClick={onUploadClick}
                    >
                        <Icon icon="material-symbols:upload-sharp" width="30" height="30" />Import
                    </CdsButton>
                }
                <CdsButton
                    action="outline"
                    style={{ marginLeft: '1%' }}
                    onClick={() => { setRefreshValue(!refreshValue) }}
                >
                    <Icon icon="tdesign:refresh" width="25" height="25" />Refresh
                </CdsButton>
            </div>
            {activePanel === "draft-me" &&
                <MyGraphList
                    FramePort={FramePort}
                    openDeleteModal={openDeleteModal}
                    myGraphList={myGraphList}
                    setMyGraphList={setMyGraphList}
                    refreshValue={refreshValue}
                />
            }
            {activePanel === "draft-all" &&
                <AllGraphList
                    FramePort={FramePort}
                    allGraphList={allGraphList}
                    setAllGraphList={setAllGraphList}
                    refreshValue={refreshValue}
                />
            }
            {activePanel === "job" &&
                <JobGraphList
                    FramePort={FramePort}
                    jobGraphList={jobGraphList}
                    setJobGraphList={setJobGraphList}
                    refreshValue={refreshValue}
                />
            }
            {activePanel === "workflow" &&
                <WorkflowGraphList
                    FramePort={FramePort}
                    workflowGraphList={workflowGraphList}
                    setWorkflowGraphList={setWorkflowGraphList}
                    refreshValue={refreshValue}
                />
            }
        </>
    )
}