import React from 'react';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, timesIcon } from '@cds/core/icon';
import Parameter from '../options/Parameter';
import Node from '../../components/Node';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';


ClarityIcons.addIcons(timesIcon);

class JoinNode extends React.Component {

  constructor(props) {
    super(props);
    this.id = props.id;
    this.data = props.data;
    this.isConnectable = props.isConnectable;
    this.handleConditionChange = this.handleConditionChange.bind(this);
    this.handleConditionAdd = this.handleConditionAdd.bind(this);
    this.handleConditionRemove = this.handleConditionRemove.bind(this);
    this.onChangeJoinType = this.onChangeJoinType.bind(this);
  }

  render() {
    return (
      <Node
        id={this.id}
        nodeId={this.id}
        header="Join"
        nodeClass="join-node"
        nodeData={this.data.node_data}
        nodeStatus={this.props.data.status}
        isConnectable={this.isConnectable}
        exportHandleList={[
            {
              type: "Dataset", 
              name: ""
            }
          ]
        }
        importHandleList={[
          {
            type: "Dataset", 
            name: "Left"
          },
          {
            type: "Dataset", 
            name: "Right"
          }
        ]
      }>
          <Parameter
            header="Join Parameters">
            <cds-select layout="vertical">
              <label>Join type</label>
              <select className="nodrag" style={{width: '80px', fontSize: '10px'}} onChange={this.onChangeJoinType} selected={this.data.dag_data.params.join_type}>
                <option value="left">Left</option>
                <option value="right">Right</option>
              </select>
            </cds-select>
            On columns:
            <div cds-layout="vertical gap:sm">
              {this.data.dag_data.params.columns.map((singleCondition, index) => (
                <div key={"join_condition-" + index}>
                  <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                    <cds-input layout="vertical">
                      <label>Left</label>
                      <input 
                        id="text" 
                        name="left" 
                        onChange={(e) => this.handleConditionChange(e, index)} 
                        className="nodrag"
                        value={singleCondition.left} 
                        style={{width: '60px'}}
                      />
                    </cds-input>
                    <cds-input layout="vertical">
                      <label>Right</label>
                      <input 
                        id="text" 
                        name="right" 
                        onChange={(e) => this.handleConditionChange(e, index)} 
                        className="nodrag"
                        value={singleCondition.right} 
                        style={{width: '60px'}}
                      />
                    </cds-input>
                    { this.data.dag_data.params.columns.length > 1 &&
                      <CdsIcon className="custom-button" size="icon" shape="times" onClick={() => this.handleConditionRemove(index)}></CdsIcon>}
                  </div>
                </div>
              ))}
              {this.data.dag_data.params.columns.length < 5 &&
              <div className='custom-button' onClick={this.handleConditionAdd}>
                Add Condition
              </div>
              }
            </div>
        </Parameter>
      </Node>
    );
  }

  handleConditionChange(e, index) {
    const {name, value} = e.target;
    const list = [...this.data.dag_data.params.columns];
    list[index][name] = value;
    this.data.dag_data.params.columns = list;
    this.forceUpdate();
  }

  handleConditionAdd() {
    this.data.dag_data.params.columns = [...this.data.dag_data.params.columns, {"left" : "", "right" : ""}];
    this.forceUpdate();
  }

  handleConditionRemove(index) {
    const list = [...this.data.dag_data.params.columns];
    list.splice(index, 1);
    this.data.dag_data.params.columns = list;
    this.forceUpdate();
  }

  onChangeJoinType(evt) {
    this.data.dag_data.params.join_type = evt.target.value;
  }

}

export default JoinNode;
