import React, { useState, useContext } from 'react';
import { CdsButton } from '@cds/react/button';
import { CdsModal, CdsModalHeader, CdsModalContent, CdsModalActions } from '@cds/react/modal';
import { CdsCard } from '@cds/react/card';
import { CdsInput } from '@cds/react/input'
import { ClarityIcons, plusIcon } from '@cds/core/icon';
import { Icon } from '@iconify/react';
import { useReactFlow } from 'reactflow';
import { toast } from 'react-toastify';

import WorkspaceList from './components/WorkspaceList';

import ExportConversionJob from '../../../../../../services/Graph/Dag/ExportConversion';

import connectionInfo from '../../../../../../services/PortCommunication';

import { AddFileToWorkspace } from '../../../../../../services/FrameUtils';

import "./ExportMenu.css"


ClarityIcons.addIcons(plusIcon);

interface ExportMenuInterface {
    currentGraphID: string;
    currentGraphName: string;
    compatibleFramework: Record<LanguageName, Boolean>;
}
function ExportMenu({ currentGraphID, currentGraphName, compatibleFramework }: ExportMenuInterface) {

    const [openModal, setOpenModal] = useState(false);
    const [selectionMode, setSelectionMode] = useState("selection");
    const [workspaceSelected, setWorkspaceSelected] = useState("");
    const [workspacePath, setWorkspacePath] = useState("/tmp")
    const { getNodes, toObject } = useReactFlow();

    const languageList: Array<LanguageName> = ["pandas", "java", "flink", "pyspark", "beam", "dask"]
    const languageIcon = {
        "pandas": "simple-icons:pandas",
        "java": "simple-icons:apachespark",
        "flink": "simple-icons:apacheflink",
        "pyspark": "simple-icons:apachespark",
        "beam": "simple-icons:apache",
        "dask": "simple-icons:dask"
    }
    const languageName = {
        "pandas": "Pandas",
        "java": "Spark",
        "flink": "Flink",
        "pyspark": "PySpark",
        "beam": "Beam",
        "dask": "Dask"
    }

    const ExportGraph = () => {
        const flowObject = toObject();
        const flow = ExportConversionJob({ ...flowObject }, currentGraphID);
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(flow.dag, null, "\t")
        )}`;

        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "data.json";

        link.click();
    }

    const ExportFlow = () => {
        const flowObject = toObject();
        const flow = ExportConversionJob({ ...flowObject }, currentGraphID);
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(flow, null, "\t")
        )}`;

        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "data.json";

        link.click();
    }

    const ExportCode = (languageName: LanguageName) => {
        if (compatibleFramework[languageName]) {
            const flow = toObject();
            const dag = ExportConversionJob({ ...flow }, currentGraphID);
            connectionInfo.port.postMessage(
                {
                    "type": "export_code",
                    "data": {
                        "language": languageName,
                        "dag": dag.dag
                    }
                });
            ResetCloseModal()
        }
    }

    const ExportCodeToWorkSpace = (languageName: LanguageName) => {
        const flow = toObject();
        const dag = ExportConversionJob({ ...flow }, currentGraphID);
        const dag_string = JSON.stringify(dag.dag, null, 2)

        ResetCloseModal()
        toast.promise(
            AddFileToWorkspace(
                languageName,
                dag_string,
                workspaceSelected,
                workspacePath
            ),
            {
                pending: 'Exporting code to workspace',
                success: 'Code successfully added to workspace',
                error: "Couldn't export graph"
            }
        )
    }

    const ResetWorkspacePath = () => {
        var today = new Date();
        setWorkspacePath("/graph_code/" + currentGraphName + "-" + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + "-" + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds())
    }

    const OpenModal = () => {
        ResetWorkspacePath()
        setOpenModal(true)
    }

    const ResetCloseModal = () => {
        setOpenModal(false)
        setSelectionMode("selection")
        setWorkspaceSelected("")
    }

    return (
        <div style={{ display: "flex" }}>
            <CdsButton action="flat" status="primary" onClick={() => OpenModal()}>Export</CdsButton>
            <CdsModal size="lg" hidden={!openModal} onCloseChange={() => ResetCloseModal()} cds-theme="dark">
                {
                    selectionMode === "selection" &&
                    <>
                        <CdsModalHeader><h2>Where do you want to export your graph?</h2></CdsModalHeader>
                        <CdsModalContent>
                            <div className="export-selection">
                                <CdsCard className="exportCard" cds-theme="dark" onClick={() => setSelectionMode("local")}>
                                    <div className="card-content">
                                        <div className="card-icon-xl">
                                            <Icon icon="ic:sharp-download" />
                                        </div>
                                        <div className="card-title">On your computer</div>
                                    </div>
                                </CdsCard>
                                <CdsCard className="exportCard" cds-theme="dark" onClick={() => setSelectionMode("workspace")}>
                                    <div className="card-content">
                                        <div className="card-icon-xl">
                                            <Icon icon="ic:sharp-computer" />
                                        </div>
                                        <div className="card-title">On Graal Workspace</div>
                                    </div>
                                </CdsCard>
                            </div>
                        </CdsModalContent>
                        <CdsModalActions>
                            <CdsButton onClick={() => ResetCloseModal()}>Close</CdsButton>
                        </CdsModalActions>
                    </>
                }
                {
                    selectionMode === "local" &&
                    <>
                        <CdsModalHeader><h2>Select the language</h2></CdsModalHeader>
                        <CdsModalContent>
                            <div className="export-options">
                                {languageList.map((language, index) => (
                                    <>
                                        {
                                            compatibleFramework[language] &&
                                            <CdsCard className="exportCard " cds-theme="dark" onClick={() => ExportCode(language)}>
                                                <div className="card-content">
                                                    <div className="card-icon">
                                                        <Icon icon={languageIcon[language]} />
                                                    </div>
                                                    <div className="card-title">{languageName[language]}</div>
                                                </div>
                                            </CdsCard>
                                        }
                                    </>

                                ))}
                                <CdsCard className="exportCard" cds-theme="dark" onClick={() => ExportGraph()}>
                                    <div className="card-content">
                                        <div className="card-icon">
                                            <Icon icon="la:dev" />
                                        </div>
                                        <div className="card-title">Graph (dev only)</div>
                                    </div>
                                </CdsCard>
                                <CdsCard className="exportCard" cds-theme="dark" onClick={() => ExportFlow()}>
                                    <div className="card-content">
                                        <div className="card-icon">
                                            <Icon icon="la:dev" />
                                        </div>
                                        <div className="card-title">Flow (dev only)</div>
                                    </div>
                                </CdsCard>
                            </div>
                        </CdsModalContent>
                        <CdsModalActions>
                            <CdsButton onClick={() => setSelectionMode("selection")}>Back</CdsButton>
                            <CdsButton onClick={() => ResetCloseModal()}>Close</CdsButton>
                        </CdsModalActions>
                    </>
                }
                {
                    selectionMode === "workspace" &&
                    <>
                        {
                            workspaceSelected == "" &&
                            <>
                                <CdsModalHeader><h2>Select the workspace</h2></CdsModalHeader>
                                <CdsModalContent>
                                    <WorkspaceList
                                        setWorkspaceSelected={setWorkspaceSelected}
                                    />
                                </CdsModalContent>
                                <CdsModalActions>
                                    <CdsButton onClick={() => setSelectionMode("selection")}>Back</CdsButton>
                                    <CdsButton onClick={() => ResetCloseModal()}>Close</CdsButton>
                                </CdsModalActions>
                            </>
                            ||
                            <>
                                <CdsModalHeader><h2>Select the language</h2></CdsModalHeader>
                                <CdsModalContent>
                                    <CdsInput layout="vertical">
                                        <label>Folder path</label>
                                        <input
                                            id="workspace_path"
                                            name="left"
                                            spellCheck="false"
                                            onChange={(e) => setWorkspacePath(e.target.value)}
                                            className="nodrag"
                                            value={workspacePath}
                                            style={{ width: '60px' }}
                                        />
                                    </CdsInput>
                                    <div className="export-options">
                                        <div className="row">
                                            <CdsCard className="exportCard" cds-theme="dark" onClick={() => ExportCodeToWorkSpace("java")}>
                                                <div className="card-content">
                                                    <div className="card-icon">
                                                        <Icon icon="simple-icons:apachespark" />
                                                    </div>
                                                    <div className="card-title">Spark</div>
                                                </div>
                                            </CdsCard>
                                            <CdsCard className="exportCard" cds-theme="dark" onClick={() => ExportCodeToWorkSpace("pandas")}>
                                                <div className="card-content">
                                                    <div className="card-icon">
                                                        <Icon icon="simple-icons:pandas" />
                                                    </div>
                                                    <div className="card-title">Pandas</div>
                                                </div>
                                            </CdsCard>
                                            <CdsCard className="exportCard" cds-theme="dark" onClick={() => ExportCodeToWorkSpace("flink")}>
                                                <div className="card-content">
                                                    <div className="card-icon">
                                                        <Icon icon="simple-icons:apacheflink" />
                                                    </div>
                                                    <div className="card-title">Flink</div>
                                                </div>
                                            </CdsCard>
                                        </div>
                                        <div className="row">
                                            <CdsCard className="exportCard" cds-theme="dark" onClick={() => ExportCodeToWorkSpace("pyspark")}>
                                                <div className="card-content">
                                                    <div className="card-icon">
                                                        <Icon icon="simple-icons:apachespark" />
                                                    </div>
                                                    <div className="card-title">PySpark</div>
                                                </div>
                                            </CdsCard>
                                            <CdsCard className="exportCard" cds-theme="dark" onClick={() => ExportCodeToWorkSpace("beam")}>
                                                <div className="card-content">
                                                    <div className="card-icon">
                                                        <Icon icon="simple-icons:apache" />
                                                    </div>
                                                    <div className="card-title">Beam</div>
                                                </div>
                                            </CdsCard>
                                            <CdsCard className="exportCard" cds-theme="dark" onClick={() => ExportCodeToWorkSpace("dask")}>
                                                <div className="card-content">
                                                    <div className="card-icon">
                                                        <Icon icon="simple-icons:dask" />
                                                    </div>
                                                    <div className="card-title">Dask</div>
                                                </div>
                                            </CdsCard>
                                        </div>
                                    </div>
                                </CdsModalContent>
                                <CdsModalActions>
                                    <CdsButton onClick={() => setWorkspaceSelected("")}>Back</CdsButton>
                                    <CdsButton onClick={() => ResetCloseModal()}>Close</CdsButton>
                                </CdsModalActions>
                            </>
                        }
                    </>
                }
            </CdsModal>
        </div >
    );
}

export default ExportMenu;
