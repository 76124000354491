import React, { useState, useEffect, useRef } from 'react';
import { CdsButton } from '@cds/react/button';
import { CdsModal, CdsModalHeader, CdsModalContent, CdsModalActions } from '@cds/react/modal';
import { ClarityIcons, plusIcon } from '@cds/core/icon';
import { useReactFlow } from 'reactflow';

import { CreateSession, CreateNotebook, RunSession } from '../../../../../../services/SessionService';
import ExportConversion from '../../../../../../services/Graph/Dag/ExportConversion';


ClarityIcons.addIcons(plusIcon);

interface InstanceMenuInterface {
    currentGraphID: string;
    currentGraphName: string;
    addLogMessage: (newMessage: LogMessage) => void;
    setCurrentData: any;
    setCurrentStatus: (value: NodeStatus) => void;
}
function InstanceMenu({ currentGraphID, currentGraphName, setCurrentData, setCurrentStatus, addLogMessage }: InstanceMenuInterface) {

    const { setNodes, toObject } = useReactFlow();

    const [openEdit, setOpenEdit] = useState(false);
    const [status, setStatus] = useState<InstanceStatus>("offline")
    const statusRef = useRef<InstanceStatus>("offline");
    const [sessionID, setSessionID] = useState("")

    const colorStatus = { "offline": "red", "ready": "green", "starting": "orange", "error": "red" }
    const buttonStatus = { "offline": "Start", "ready": "Stop", "starting": "Stop", "error": "Stop" }
    const messageStatus = { "offline": "Offline", "ready": "Ready", "starting": "Starting", "error": "Error" }

    useEffect(() => {
        statusRef.current = status;

        if (status === "starting") {
            fetchSession()
        }
        else if (status === "offline") {
            setSessionID("")
        }
    }, [status]);

    useEffect(() => {
        if (sessionID != "" && status === "starting") {
            addNotebooktoSession()
        }
    }, [sessionID]);

    const fetchSession = () => {
        console.log("creating session")
        CreateSession("Live-Coding: " + currentGraphName)
            .then((data) => {
                if (status === "starting") {
                    setSessionID(data.session_id)
                    console.log("session updated")
                }
            })
            .catch((err) => {
                console.log(err)
            })
    };

    const addNotebooktoSession = () => {
        if (statusRef.current === "starting") {
            CreateNotebook(sessionID)
                .then((data) => {
                    console.log(data)
                    setStatus("ready")
                    addLogMessage(
                        {
                            "status": "success",
                            "message": "Session is ready."
                        }
                    )
                })
                .catch((err) => {
                    console.log(err)
                    setTimeout(addNotebooktoSession, 3000);
                })
        }
    }

    const runJob = () => {
        setCurrentStatus("pending")
        addLogMessage(
            {
                "status": "pending",
                "message": "Executing your graph..."
            }
        )
        const flowObject = toObject();
        const flow = ExportConversion({ ...flowObject }, currentGraphID);
        const dag = JSON.stringify(flow.dag, null, "\t");
        RunSession(sessionID, dag).then(
            (data) => {
                console.log(data)
                setCurrentData(data.response)
                setCurrentStatus("success")
                addLogMessage(
                    {
                        "status": "success",
                        "message": "Graph successfully executed."
                    }
                )
            }
        ).catch(
            (error) => {
                setCurrentStatus("error")
                addLogMessage(
                    {
                        "status": "error",
                        "message": "Error during execution.\n" + error
                    }
                )
            }
        )


    }

    const editInstance = () => {
        setOpenEdit(true)
    }

    const toggleInstance = () => {
        if (status === "offline") { // Start instance
            setStatus("starting")
            addLogMessage(
                {
                    "status": "pending",
                    "message": "Starting a session..."
                }
            )
        }
        else { // Kill instance
            setStatus("offline")
            addLogMessage(
                {
                    "status": "info",
                    "message": "Session stopped."
                }
            )
            setCurrentStatus("none")
        }
    }

    return (
        <div style={{ display: "flex" }}>
            <CdsButton disabled={status !== "ready"} action="flat" status="primary" onclick={runJob}>Run</CdsButton>
            <CdsButton style={{ width: "80px" }} action="flat" status="primary" onclick={toggleInstance}>{buttonStatus[status]}</CdsButton>
            <CdsButton disabled={status !== "offline"} action="flat" status="primary" onclick={editInstance}>Edit</CdsButton>
            <div style={{ display: "flex", alignItems: "center", width: "100px", marginRight: "15px" }}>
                <div style={{ height: "16px", width: "16px", backgroundColor: colorStatus[status], borderRadius: "8px", marginRight: "5px", marginLeft: "5px" }}>
                </div>
                <div style={{ color: "#40647a", fontFamily: "Clarity City", fontSize: "15px" }}>
                    {messageStatus[status]}
                </div>
            </div>
            <CdsModal size="lg" hidden={!openEdit} onCloseChange={() => setOpenEdit(false)} cds-theme="dark">
                <CdsModalHeader><h2>Edit Instance</h2></CdsModalHeader>
                <CdsModalContent>

                </CdsModalContent>
                <CdsModalActions>
                    <CdsButton onClick={() => setOpenEdit(false)}>Close</CdsButton>
                </CdsModalActions>
            </CdsModal>
        </div>
    );
}

export default InstanceMenu;
