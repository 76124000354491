import connectionInfo from "../../PortCommunication";


export function VerifyDag(dag) {

    let communicationChannel = new MessageChannel();

    return new Promise<void>((resolve, reject) => {

        communicationChannel.port1.onmessage = (event) => {

            if (event.data.data.result === "success") {
                resolve(event.data.data.response)
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "verify_code",
                "data": {
                    "dag": dag
                }
            }, [communicationChannel.port2]);
    }
    )
}