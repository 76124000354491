
function renameKey(dict, oldKey, newKey) {
    console.log("Renaming " + oldKey)
    if (oldKey !== newKey) {
        dict[ newKey ] = dict[ oldKey ];
        delete dict[oldKey];
    }
    console.log("Rename successfull")
    return dict
}

function ExportFilter(params) {
    console.log("converting filter task");
    console.log(params)
    delete params.id;
    if (params["combinator"] !== undefined){
        if (params["not"] !== undefined) {
            delete params.not;
        }
        var params = renameKey({...params}, "combinator", "type");
        var params = renameKey({...params}, "rules", "conditions");
        var conditions = [];
        console.log(params)
        for (var index in params["conditions"]) {
            conditions.push(ExportFilter({...params["conditions"][index]}))
        }
        params.conditions = conditions;
    }
    if (params["operator"] !== undefined){
        delete params["valueSource"];
        var params = renameKey({...params}, "operator", "type");
        var params = renameKey({...params}, "field", "left");   
        var params = renameKey({...params}, "value", "right"); 
    }

    return params
}

export default ExportFilter;