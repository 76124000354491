import {useState} from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import {CdsAccordionPanel} from '@cds/react/accordion'

function Parameter({header, children}) {
    const [isOpen, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!isOpen)
        }
    
  return (
    <CdsAccordionPanel cds-motion="on" onExpandedChange={toggle} expanded={isOpen}>
        <cds-accordion-header>{header}</cds-accordion-header>
        <cds-accordion-content>
          <div cds-layout="vertical gap:md">
            {children}
          </div>
        </cds-accordion-content>
    </CdsAccordionPanel>
  );
}

export default Parameter;
