import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import Parameter from '../Parameter';

import Checkbox from '../../../components/inputs/Checkbox';
import StringList from '../../../components/inputs/StringList';

class FileObject extends React.Component {

    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.onChangePath = this.onChangePath.bind(this);
        this.onChangeSeparator = this.onChangeSeparator.bind(this);
        this.onChangeCompression = this.onChangeCompression.bind(this);
        this.onChangeMode = this.onChangeMode.bind(this);
        this.toggle = this.toggle.bind(this)
        this.open = false
        if (this.data.params.object.type == "") {
          this.currentPath = this.data.params.object.path
        } else {
          this.currentPath = this.data.params.object.path + "." + this.data.params.object.type 
        }
        
      }
    
    render () {
        return(
          <Parameter
            header="File Parameters">
                    <cds-input layout="vertical">
                      <label>Dataset path</label>
                      <input placeholder="example.csv" id={this.id} name="text" onChange={this.onChangePath}  value={this.currentPath} className="nodrag" required />
                    </cds-input>
                    {this.data.params.object.type === 'csv' &&
                      <cds-select layout="vertical">
                      <label>Separator</label>
                      <select className="nodrag" style={{width: '50px', fontSize: '10px'}} onChange={this.onChangeSeparator} value={this.data.params.object.separator}>
                        <option value=",">,</option>
                        <option value=";">;</option>
                        <option value=".">.</option>
                      </select>
                    </cds-select>}
                    {this.data.params.object.type === 'parquet' && this.data.type === 'write' &&
                    <>
                      <cds-select layout="vertical">
                        <label>Compression</label>
                        <select className="nodrag" style={{width: '50px', fontSize: '10px'}} onChange={this.onChangeCompression} selected={this.data.params.object.compression}>
                          <option value="none">None</option>
                          <option value="uncompressed">Uncompressed</option>
                          <option value="snappy">Snappy</option>
                          <option value="gzip">gzip</option>
                          <option value="Izo">Izo</option>
                          <option value="brotli">brotli</option>
                          <option value="Iz4">Iz4</option>
                          <option value="zstd">zstd</option>
                        </select>
                      </cds-select>
                      <cds-select layout="vertical">
                        <label>Save mode</label>
                        <select className="nodrag" style={{width: '50px', fontSize: '10px'}} onChange={this.onChangeMode} selected={this.data.params.object.mode}>
                          <option value="overwrite">Overwrite</option>
                          <option value="append">Append</option>
                        </select>
                      </cds-select>
                      <StringList 
                        main_label="Partition By"
                        condition_label="Column name"
                        max_length={6}
                        data={this.data.params.object.partition_by}
                        handleDataChange= {(e) => {this.data.params.object.partition_by = e}}
                      />
                    </>
                    }
                    {this.data.params.object.type === 'csv' &&
                      <Checkbox 
                        label="Header"
                        data={this.data.params.object.header}
                        handleChange = {(e) => {this.data.params.object.header = !this.data.params.object.header}}/>
                    }
                    {this.data.params.object.type === 'csv' &&
                      <Checkbox 
                        label="Index"
                        data={this.data.params.object.index}
                        handleChange = {(e) => {this.data.params.object.index = !this.data.params.object.index}}/>
                    }
          </Parameter>
        )
    }

    toggle(evt) {
      this.open = !this.open
      this.forceUpdate()
    }

    onChangePath(evt) {
        if ( evt.target.value.includes('.')) {
          const input = evt.target.value.split('.')
          this.data.params.object.type = input.pop().toLowerCase();
          this.data.params.object.path = input.join();
          
        } else {
          this.data.params.object.path = evt.target.value
        }
        this.currentPath = evt.target.value;
        this.forceUpdate();
      }
    
    onChangeSeparator(evt) {
      this.data.params.object.separator = evt.target.value;
      this.forceUpdate();
    }

    onChangeCompression(evt) {
      this.data.params.object.compression = evt.target.value;
      this.forceUpdate();
    }

    onChangeMode(evt) {
      this.data.params.object.mode = evt.target.value;
      this.forceUpdate();
    }

}

export default FileObject;
