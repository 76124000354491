import connectionInfo from "./PortCommunication";

export function CreateSession(sessionName) {

    let communicationChannel = new MessageChannel();

    return new Promise((resolve, reject) => {
        communicationChannel.port1.onmessage = (event) => {
            if (event.data.data.result === "success") {
                resolve(event.data.data)
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "create_session",
                "data": {
                    "name": sessionName
                }
            }, [communicationChannel.port2]);
    }
    )
}

export function CreateNotebook(sessionID) {

    let communicationChannel = new MessageChannel();

    return new Promise((resolve, reject) => {
        communicationChannel.port1.onmessage = (event) => {
            if (event.data.data.result === "success") {
                resolve(event.data.data)
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "create_notebook",
                "data": {
                    "session_id": sessionID
                }
            }, [communicationChannel.port2]);
    }
    )
}

export function RunSession(sessionID, dag) {

    let communicationChannel = new MessageChannel();

    return new Promise((resolve, reject) => {
        communicationChannel.port1.onmessage = (event) => {
            if (event.data.data.result === "success") {
                resolve(event.data.data)
            } else {
                reject()
            }
        }

        connectionInfo.port.postMessage(
            {
                "type": "run_session",
                "data": {
                    "session_id": sessionID,
                    "dag": dag
                }
            }, [communicationChannel.port2]);
    }
    )
}
