import React, { useCallback, useState } from 'react';
import { ClarityIcons, timesIcon } from '@cds/core/icon';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { QueryBuilder } from 'react-querybuilder';
import { ValueSelectorProps, ActionElement  } from 'react-querybuilder';
import { CdsModal, CdsModalHeader, CdsModalContent, CdsModalActions } from '@cds/react/modal';
import Parameter from '../options/Parameter';
import Node from '../../components/Node';

import './FilterNode.css';

ClarityIcons.addIcons(timesIcon);

const AutocompleteValueSelector = (props) => (
  <input
    className="rule-fields"
    defaultValue={props.value}
    onChange={e => props.handleOnChange(e.target.value)}
  />
);


class FilterNode extends React.Component {

  constructor(props) {
    super(props);
    this.id = props.id;
    this.data = props.data;
    this.isConnectable = props.isConnectable;
    this.query = this.data.dag_data.params;
    this.handleQueryChange = this.handleQueryChange.bind(this);
  }

  render() {  return(
    <Node
      id={this.id}
      nodeId={this.id}
      header="Filter"
      nodeClass="filter-node"
      nodeData={this.data.node_data}
      nodeStatus={this.props.data.status}
      isConnectable={this.isConnectable}
      exportHandleList={[
        {
          type: "Dataset", 
          name: ""
        }
      ]}
      importHandleList={[
        {
          type: "Dataset", 
          name: ""
        }
      ]
    }>
      <Parameter
          header="Rules"
          >
          <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
            <QueryBuilder
              query={this.query}
              onQueryChange={this.handleQueryChange}
              controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
              controlElements={{
                fieldSelector: AutocompleteValueSelector,
                addGroupAction: props => (props.level < 2 ? <ActionElement {...props} /> : null),
              }}
              operators={[
                { name: 'equal', label: '=' },
                { name: 'not_equal', label: '!=' },
                { name: 'less', label: '<' },
                { name: 'greater', label: '>' },
                { name: 'less_or_equal', label: '<=' },
                { name: 'greater_or_equal', label: '>=' },
                { name: 'not_null', label: 'is not null' },
                { name: 'in', label: 'in' },
                { name: 'between', label: 'between' },
                { name: 'like', label: 'like' }
              ]}
            />
          </QueryBuilderDnD>
          </Parameter>
    </Node>
  )}

  handleQueryChange(newQuery) {
    this.query = newQuery;
    this.data.dag_data.params = newQuery;
    this.forceUpdate();
  }
}

export default FilterNode;