import React from 'react';
import { NodeProps } from 'reactflow';

import Node from './components/Node';
import ParameterFactory from './Job/options/ParameterFactory';


interface NodeState {
}
class DynamicNode extends React.Component<NodeProps,NodeState> {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Node
        key={this.props.id}
        nodeId={this.props.id}
        nodeData={this.props.data.node_data}
        nodeStatus={this.props.data.status}
        isConnectable={this.props.isConnectable}>
          {this.props.data.node_data.parameters.map((parameter_id, index) => (
            ParameterFactory(this.props.id,this.props.data.dag_data,parameter_id)
          ))}
      </Node>
    );
  }

}

export default DynamicNode;