import { Handle, Position, useReactFlow } from 'reactflow';
import { CdsIcon } from '@cds/react/icon';
import { Icon } from '@iconify/react';
import { ClarityIcons, timesIcon } from '@cds/core/icon';

import '@cds/core/styles/theme.dark.min.css';
import './Node.css';
import { CdsAccordion } from '@cds/react/accordion'
import InputHandle from './handles/InputHandle.js';

ClarityIcons.addIcons(timesIcon);

function Node({ nodeId, isConnectable, children, nodeData, nodeStatus }) {


  const statusIcon = {
    "pending": "line-md:loading-twotone-loop",
    "error": "material-symbols:error-outline",
    "warning": "material-symbols:warning-outline",
    "success": "material-symbols:check-circle-outline"
  }

  const statusColor = {
    "pending": "#fff2f0",
    "error": "#e02200",
    "warning": "#ffb92e",
    "success": "#42810e",
  }

  const { deleteElements, getNode } = useReactFlow();

  const deleteNode = (id) => {

    deleteElements({ nodes: [getNode(id)] });
  }

  if (nodeData.input) {
    for (var i = 0; i < nodeData.input.length; i++) {
      nodeData.input[i]["id"] = "input_" + i
    }
  }
  if (nodeData.output) {
    for (var i = 0; i < nodeData.output.length; i++) {
      nodeData.output[i]["id"] = "output_" + i
    }
  }

  const onNameChange = (event) => {
    const value = event.currentTarget.textContent;
    nodeData.name = value.slice(0, 46);
  }

  var inputConnectionLimit = 1

  // Technical Debt!!!
  // It's ugly but it works
  // TODO: Rework the input connection limit to add it as a node parameter
  console.log(nodeData.icon)
  if (["carbon:batch-job", "material-symbols:start"].includes(nodeData.icon)) {
    inputConnectionLimit = 15
  }

  console.log(inputConnectionLimit)

  return (
    <div id={nodeId} cds-theme="dark">
      <div>
        <div className='header'>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon icon={nodeData.icon} />
            <label htmlFor="text" style={{ paddingLeft: "5px", maxWidth: "240px", maxHeight: "100px" }} contentEditable="true" suppressContentEditableWarning={true} className='nodrag' spellCheck="false" onInput={onNameChange}>{nodeData.name}</label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {nodeStatus !== "none" && <Icon icon={statusIcon[nodeStatus]} style={{ color: statusColor[nodeStatus] }} />}
            <CdsIcon className="custom-button" size="24" shape="times" onClick={() => deleteNode(nodeId)}></CdsIcon>
          </div>
        </div>
        <div className='parameters'>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div>
              {nodeData["input"] && nodeData["input"].map((element, index) => (
                <div className='input' key={'input-' + index}>
                  <div style={{ marginLeft: '3px', fontSize: '12px' }} datatype={element.type}>{element.type} {element.name}</div>
                  <InputHandle type="target" position={Position.Left} id={element.id} isConnectable={inputConnectionLimit} datatype={element.type} />
                </div>
              ))}
            </div>
            <div>
              {nodeData["output"] && nodeData["output"].map((element, index) => (
                <div className='output' key={'output-' + index}>
                  <div style={{ marginRight: '3px', fontSize: '12px' }} datatype={element.type}>{element.type} {element.name}</div>
                  <Handle type="source" position={Position.Right} id={element.id} isConnectable={isConnectable} datatype={element.type} />
                </div>
              ))}
            </div>
          </div>
          <div className='parameters' style={{ paddingBottom: '2px' }}>
            <CdsAccordion>
              {children}
            </CdsAccordion>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Node;
