import React from 'react';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, timesIcon } from '@cds/core/icon';
import Parameter from '../options/Parameter';
import Node from '../../components/Node';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';

ClarityIcons.addIcons(timesIcon);

class OrderNode extends React.Component {

  constructor(props) {
    super(props);
    this.id = props.id;
    this.data = props.data;
    this.isConnectable = props.isConnectable;
    this.handleConditionChange = this.handleConditionChange.bind(this);
    this.handleConditionAdd = this.handleConditionAdd.bind(this);
    this.handleConditionRemove = this.handleConditionRemove.bind(this);
  }

  render() {
    return (
      <Node
        id={this.id}
        nodeId={this.id}
        header="Order"
        nodeClass="order-node"
        nodeData={this.data.node_data}
        nodeStatus={this.props.data.status}
        isConnectable={this.isConnectable}
        exportHandleList={[
          {
            type: "Dataset", 
            name: ""
          }
        ]}
        importHandleList={[
          {
            type: "Dataset", 
            name: ""
          }
        ]
      }>
         <Parameter
          header="Rules"
          >
              {this.data.dag_data.params.columns.map((singleCondition, index) => (
                <div>
                  <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                      <cds-input layout="vertical">
                      <label>Column name</label>
                      <input 
                        id="text" 
                        name="name" 
                        onChange={(e) => this.handleConditionChange(e, index)} 
                        className="nodrag"
                        value={singleCondition.name} 
                        style={{width: '60px'}}
                        />
                        </cds-input>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                      <cds-select layout="vertical">
                      <label>Direction</label>
                      <select  
                        name="direction" 
                        onChange={(e) => this.handleConditionChange(e, index)} 
                        className="nodrag"
                        value={singleCondition.direction} 
                        style={{width: '80px', fontSize: '10px'}}>
                          <optgroup style={{fontSize: '20px'}}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                          </optgroup>
                      </select>
                      </cds-select>
                    </div>
                    { this.data.dag_data.params.columns.length > 1 &&
                      <CdsIcon className="custom-button" size="icon" shape="times" onClick={() => this.handleConditionRemove(index)}></CdsIcon>}
                  </div>
                </div>
              ))}
              {this.data.dag_data.params.columns.length < 5 &&
                  <div className='custom-button' onClick={this.handleConditionAdd}>
                    Add Condition
                  </div>
              }
          </Parameter>
      </Node>
    );
  }


  handleConditionChange = (e, index) => {
    const {name, value} = e.target;
    const list = [...this.data.dag_data.params.columns];
    list[index][name] = value;
    this.data.dag_data.params.columns = list;
    this.forceUpdate();
  }

  handleConditionAdd = () => {
    this.data.dag_data.params.columns = [...this.data.dag_data.params.columns, {name : "", direction : ""}]
    this.forceUpdate();
  }

  handleConditionRemove = (index) => {
    const list = [...this.data.dag_data.params.columns];
    list.splice(index, 1);
    this.data.dag_data.params.columns = list;
    this.forceUpdate();
  }

}

export default OrderNode;
