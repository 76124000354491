import React, {
    useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { CdsCard } from '@cds/react/card';

import { Icon } from '@iconify/react';

import './index.css'
import { CreateGraph, DeleteGraph } from '../../services/Graph/GraphService';
import DeleteModal from './components/deleteModal/deleteModal';
import ProjectNavigation from './components/projectNavigation/projectNavigation';


export default function Index({ FramePort }) {

    const navigate = useNavigate();
    const [myGraphList, setMyGraphList] = useState([]);
    const [allGraphList, setAllGraphList] = useState([]);
    const [jobGraphList, setJobGraphList] = useState([]);
    const [workflowGraphList, setWorkflowGraphList] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteGraphID, setDeleteGraphID] = useState('')
    const [deleteGraphName, setDeleteGraphName] = useState('')
    const [deleteGraphIndex, setDeleteGraphIndex] = useState(0)

    const createNewJobGraph = () => {
        CreateGraph('job')
            .then((newID) => {
                navigate('/draw/job/draft/' + newID);
            })
    }

    const createNewWorkflowGraph = () => {
        CreateGraph('workflow')
            .then((newID) => {
                navigate('/draw/workflow/draft/' + newID);
            })
    }

    const deleteProject = (graphID, index) => {
        // Remove from the backend
        DeleteGraph(graphID)
            .then(() => {
                // console.log("Graph deleted")
            })
            .catch((error) => {
                // console.error("Couldn't delete graph");
            });
        // Remove locally for responsiveness
        const temp = [...myGraphList];
        temp.splice(index, 1);
        setMyGraphList(temp);
    }

    const duplicateProject = (graphID) => {
        // Todo
    }

    const openDeleteModal = (graphID, graphName, index) => {
        setDeleteGraphID(graphID)
        setDeleteGraphIndex(index)
        setDeleteGraphName(graphName)
        setDeleteModalOpen(true)
    }

    const moveToTemplates = () => {
        navigate('/template')
    }

    return (
        <>
            <div style={{ width: '100vw', height: '100vh' }}>
                <DeleteModal
                    isOpen={isDeleteModalOpen}
                    setOpen={setDeleteModalOpen}
                    deleteProject={deleteProject}
                    graphID={deleteGraphID}
                    graphName={deleteGraphName}
                    index={deleteGraphIndex}
                />
                <div className='startup_main'>
                    <h2>Draw</h2>
                    <h3>New Project</h3>
                    <div className='startup_content'>
                        <div className='node-grid'>
                            <CdsCard className="projectCard" cds-theme="dark" onClick={createNewJobGraph}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Icon icon="ic:sharp-plus" width="35%" height="35%" />
                                </div>
                                <div className='node-title'>
                                    <h3 style={{ margin: "0" }}>New Job</h3>
                                </div>
                            </CdsCard>
                            <CdsCard className="projectCard" cds-theme="dark" onClick={createNewWorkflowGraph}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Icon icon="ic:sharp-plus" width="35%" height="35%" />
                                </div>
                                <div className='node-title'>
                                    <h3 style={{ margin: "0" }}>New Workflow</h3>
                                </div>
                            </CdsCard>
                            <CdsCard className="projectCard" cds-theme="dark" onClick={moveToTemplates}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Icon icon="clarity:flow-chart-line" width="35%" height="35%" />
                                </div>
                                <div className='node-title'>
                                    <h3 style={{ margin: "0" }}>Start from a template</h3>
                                </div>
                            </CdsCard>
                        </div>
                    </div>
                    <ProjectNavigation
                        openDeleteModal={openDeleteModal}
                        FramePort={FramePort}
                        myGraphList={myGraphList}
                        setMyGraphList={setMyGraphList}
                        allGraphList={allGraphList}
                        setAllGraphList={setAllGraphList}
                        jobGraphList={jobGraphList}
                        setJobGraphList={setJobGraphList}
                        workflowGraphList={workflowGraphList}
                        setWorkflowGraphList={setWorkflowGraphList}
                    />
                </div>
            </div>
        </>
    );
}