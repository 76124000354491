import React, { 
    useEffect,
    useState
   } from 'react';
  import { useNavigate } from 'react-router-dom';
  import '@cds/core/card/register.js';
  import '@cds/core/progress-circle/register.js';
  
  import { Icon } from '@iconify/react';
  
  import { LoadGraphList } from '../../../../../services/Graph/GraphService';

  export default function MyGraphList( {FramePort, openDeleteModal, myGraphList, setMyGraphList, refreshValue} ) {
  
      const navigate = useNavigate();
      const [isLoading, setLoadingMode] = useState(true)

      useEffect(() => {
        setLoadingMode(true)
        LoadGraphList(FramePort, 'my_graph_list')
            .then((response) => {
                setMyGraphList(response)
                setLoadingMode(false)
            }).catch((err) => {
                console.log(err)
            })
        }, [FramePort, refreshValue]);
  
      const loadProject = (graphID, resourceType) => {
          navigate(`/draw/${resourceType}/draft/${graphID}`)
      }
    
      const duplicateProject = (graphID) => {
          // Todo
      }
  
      const parseDate = (graphDate) => {
          const graphDateParsed = new Date(Date.parse(graphDate))
          return graphDateParsed.getHours() + ":" + graphDateParsed.getMinutes() + ", " + graphDateParsed.toDateString();
      }
    
      return (
          <>
            <div className='startup_content project'>
                    { isLoading && <div className='loading_project'>
                        <cds-progress-circle size="128" line="2"></cds-progress-circle>
                    </div> || <>
                    { myGraphList.length > 0 && <div className='node-grid'>
                    { myGraphList.map((singleGraph, index) => (
                        <cds-card
                            class="projectCard"
                            cds-theme="dark"
                            key={singleGraph.id}
                            onClick={() => loadProject(singleGraph.id, singleGraph.type)}
                        >
                            <div className="project-settings" style={{
                                position: "absolute",
                                width: "25px",
                                height: "25px",
                                right: "10px",
                                top: "2%",
                                borderRadius: "5px"
                            }}
                            onClick={(event) => {openDeleteModal(singleGraph.id, singleGraph.name, index); event.stopPropagation()}}
                            >
                                <Icon icon="akar-icons:cross" width="80%" height="80%" />
                            </div>
                            <div className="project-settings" style={{
                                position: "absolute",
                                width: "25px",
                                height: "25px",
                                right: "40px",
                                top: "2%",
                                borderRadius: "5px"
                            }}
                            onClick={(event) => {}}
                            >
                                <Icon icon="pixelarticons:duplicate" width="80%" height="80%" />
                            </div>
                            <div className="project-settings" style={{
                                position: "absolute",
                                width: "25px",
                                height: "25px",
                                right: "70px",
                                top: "2%",
                                borderRadius: "5px"
                            }}
                            onClick={(event) => {}}
                            >
                                <Icon icon="ri:information-line" width="80%" height="80%" />
                            </div>
                            <img src={singleGraph.metadata.preview} style= {{ width: "100%"}}></img>
                            <div className='node-title'>
                                <h3 style={{margin: "0"}}>{singleGraph.name}</h3>
                                <div className='date'>Created: { parseDate(singleGraph.created) }</div>
                                <div className='date'>Modified: { parseDate(singleGraph.updated) }</div>
                            </div>
                        </cds-card>
                    ))}
                    </div> || 
                    <div style={{
                        width: "100%", 
                        height:"100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"}}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Icon icon="clarity:flow-chart-line" width="30%" height="30%" />
                            <div>No project yet.</div>
                        </div>    
                    </div>} </>}
                </div>
      </>
      );
  }