import React from 'react';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, timesIcon } from '@cds/core/icon';
import Parameter from '../options/Parameter';
import Node from '../../components/Node';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';

ClarityIcons.addIcons(timesIcon);

class DistinctNode extends React.Component {

  constructor(props) {
    super(props);
    this.id = props.id;
    this.data = props.data;
    this.isConnectable = props.isConnectable;
    this.handleColumnChange = this.handleColumnChange.bind(this);
    this.handleColumnAdd = this.handleColumnAdd.bind(this);
    this.handleColumnRemove = this.handleColumnRemove.bind(this);
  }

  render() {
    return (
      <Node
        id={this.id}
        nodeId={this.id}
        header="Distinct"
        nodeClass="distinct-node"
        nodeData={this.data.node_data}
        nodeStatus={this.props.data.status}
        isConnectable={this.isConnectable}
        exportHandleList={[
          {
            type: "Dataset", 
            name: ""
          }
        ]}
        importHandleList={[
          {
            type: "Dataset", 
            name: ""
          }
        ]}>
          <Parameter
            header="Column list">
                {this.data.dag_data.params.columns.map((singleCondition, index) => (
                  <div>
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                    <cds-input layout="vertical">
                      <label>Column name</label>
                      <input 
                      id="text" 
                      name="drop_col"
                      onChange={(e) => this.handleColumnChange(e, index)}
                      value={singleCondition} 
                      className="nodrag" 
                      style={{width: '60px'}}
                      />
                    </cds-input>
                    { this.data.dag_data.params.columns.length > 1 &&
                      <CdsIcon className="custom-button" size="icon" shape="times" onClick={() => this.handleColumnRemove(index)}></CdsIcon>}
                      </div>
                  </div>
                ))}
                {this.data.dag_data.params.columns.length < 5 &&
                  <div className='custom-button' onClick={this.handleColumnAdd}>
                    Add Condition
                  </div>
                }
        </Parameter>
      </Node>
    );
  }

  handleColumnChange(e, index) {
    const {name, value} = e.target;
    const list = [...this.data.dag_data.params.columns];
    list[index] = value;
    this.data.dag_data.params.columns = list;
    this.forceUpdate();
  }

  handleColumnAdd() {
    this.data.dag_data.params.columns = [...this.data.dag_data.params.columns, ""];
    this.forceUpdate();
  }

  handleColumnRemove(index) {
    const list = [...this.data.dag_data.params.columns];
    list.splice(index, 1);
    this.data.dag_data.params.columns = list;
    this.forceUpdate();
  }

}

export default DistinctNode;
