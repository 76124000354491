import React from 'react';
import { useReactFlow} from 'reactflow';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, eyeIcon, pencilIcon } from '@cds/core/icon';
import ReactMarkdown from 'react-markdown'

import "./CommentNode.css"

ClarityIcons.addIcons(eyeIcon, pencilIcon);


function CommentNode(props) {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const { deleteElements, getNode } = useReactFlow();
  
    const deleteNode = (id) => {
  
      deleteElements({ nodes: [getNode(id)] });
    }

    const handleChange = (event) => {
      const {name, value} = event.target;
      props.data.node_data.content = value;
      forceUpdate();
    }

    const switchCurrentMode = () => {
      if ( props.data.node_data.mode == "edit") {
        props.data.node_data.mode = "markdown"
      } else {
        props.data.node_data.mode = "edit"
      }
      forceUpdate();
    }

    return (
      <div className="comment_node">
          <div className="comment_header">
            <div>

            </div>
            <div>
              <CdsIcon className="custom-button" size={ props.data.node_data.mode == "edit" ? "24" : "20"} shape={ props.data.node_data.mode == "edit" ? "eye" : "pencil"} onClick={() => switchCurrentMode()}></CdsIcon>
              <CdsIcon className="custom-button" size="24" shape="times" onClick={() => deleteNode(props.id)}></CdsIcon>        
            </div>
          </div>
          <div>
            <div style={{display: props.data.node_data.mode == "edit" ? "flex" : "none"}}>
              <textarea className="nodrag edit_area" spellCheck="false" onChange={handleChange} value={props.data.node_data.content}></textarea>
            </div>
            <div style={{display: props.data.node_data.mode == "edit" ? "none" : "flex"}}>
              <ReactMarkdown className="markdown">{props.data.node_data.content}</ReactMarkdown>
            </div>
          </div>
      </div>
    );
}


export default CommentNode;
