import React, { useCallback, useState } from 'react';

import { CdsModal, CdsModalHeader, CdsModalContent, CdsModalActions } from '@cds/react/modal';
import { CdsButton } from '@cds/react/button';
import { ClarityIcons, plusIcon } from '@cds/core/icon';


ClarityIcons.addIcons(plusIcon);

function DeleteModal( {isOpen, setOpen, deleteProject, graphID, graphName, index}) {


    return(
        <div cds-theme="dark"> 
            <CdsModal  size="lg" hidden={!isOpen} onCloseChange={() => setOpen(false)}>
                <CdsModalHeader><h2>Delete Graph</h2></CdsModalHeader>
                <CdsModalContent
                    style={{color:'white'}}
                >
                    <div>Are you sure you want to delete <span style={{fontWeight: 'bold'}}>{graphName}</span>?</div>
                </CdsModalContent>
                <CdsModalActions>
                    <CdsButton action="outline" onClick={() => setOpen(false)}>
                        Cancel
                    </CdsButton>
                    <CdsButton status="danger" onClick={() => {
                        deleteProject(graphID, index);
                        setOpen(false)}}
                    >
                        Delete
                    </CdsButton>
                </CdsModalActions>
            </CdsModal>
        </div>
    )
}

export default DeleteModal;
