import React, { useState } from 'react';
import '@cds/core/progress-circle/register.js';

function LoadingScreen({}) {

    return (
      <>
        <div style={{ 
            width: '100vw', 
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center' }}>
            <div>
                <cds-progress-circle size="256" line="2"></cds-progress-circle>
                <h2 style={{color: 'white'}}>Loading Graph...</h2>
            </div>
        </div>
      </>
    );
    }

export default LoadingScreen;
