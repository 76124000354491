import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';

class Range extends React.Component {

  constructor({label, min, max, step, data, handleChange}) {
    super()
    this.label = label;
    this.min = min;
    this.max = max;
    this.step = step;
    this.data = data;
    this.handleChange = handleChange;
    this.handleDataChange = this.handleDataChange.bind(this);
  }
  
  render() {
    return (
        <div style={{display: 'flex'}}>
            <cds-range style={{ marginRight: '10px'}}>
                <label>{this.label}</label>
                <input type="range" min={this.min} max={this.max} step={this.step} className='nodrag' value={this.data} onChange={this.handleDataChange}/>
            </cds-range>
            <cds-input layout="vertical" style={{width: '90px'}}>
                <label></label>
                <input type="number" min={this.min} max={this.max} step={this.step} className="nodrag"value={this.data} onChange={this.handleDataChange}/>
            </cds-input>
        </div>
    );
  }

  handleDataChange(e) {
    const {value} = e.target;
    this.data = Math.min(Math.max(value, this.min), this.max)
    this.handleChange(this.data)
    this.forceUpdate();
  }

}

export default Range;
