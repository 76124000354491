import { ClarityIcons, plusIcon } from '@cds/core/icon';
import React, {
  useEffect,
  useContext,
  useState
} from 'react';
import {
  ReactFlowProvider
} from 'reactflow';

import { useRoutes, Navigate } from "react-router-dom";

import Index from './scenes/Index';
import NotFound from './scenes/NotFound/notfound';
import Draw from './scenes/Draw/draw';
import TemplateForm from './scenes/TemplateForm/templateform';
import TemplateList from './scenes/TemplateList/templatelist';

import 'reactflow/dist/style.css';
import './App.css';

import connectionInfo from './services/PortCommunication';

ClarityIcons.addIcons(plusIcon);


function AppWithoutProvider() {
  useEffect(() => {
    document.title = 'Low-code engine';
  }, []);

  const [communicationPort, setCommunicationPort] = useState<MessagePort>();

  window.addEventListener("message", function (event) {
    if (event.data.type === "init") {
      //console.log("init message !")
      connectionInfo.port = event.ports[0] // TODO: remove connectionInfo
      setCommunicationPort(event.ports[0])
      //Object.freeze(connectionInfo)
    }
  });

  let element = useRoutes([
    {
      path: "/",
      element: <Index FramePort={communicationPort} />
    },
    {
      path: "draw/:resourceType/:drawMode/:graphId",
      element: <Draw
        FramePort={communicationPort}
      />
    },
    {
      path: "notfound",
      element: <NotFound />
    },
    {
      path: "template/:templateId",
      element: <TemplateForm
        FramePort={communicationPort}
      />
    },
    {
      path: "template",
      element: <TemplateList
        FramePort={communicationPort}
      />
    },
    {
      path: "*",
      element: <Navigate to="/notfound" replace />
    }
  ]);

  return element;
}


export default function App() {
  return (
    <>
      <ReactFlowProvider>
        <AppWithoutProvider />
      </ReactFlowProvider>
    </>
  )
}
