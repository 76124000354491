import React, { useCallback, useState, useEffect } from 'react';
import { CdsModal, CdsModalHeader, CdsModalContent, CdsModalActions } from '@cds/react/modal';
import { CdsCard } from '@cds/react/card';
import { CdsSearch } from '@cds/react/search';
import { CdsButton } from '@cds/react/button';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, plusIcon } from '@cds/core/icon';

import ReactFlow, {
    Panel,
} from 'reactflow';

import './NodeLibrary.css'

import JobNodeLibraryData from './NodeLibraryDataJob.json';
import WorkflowLibraryData from './NodeLibraryDataWorkflow.json'
import LibraryCategory from './LibraryCategory';

ClarityIcons.addIcons(plusIcon);

interface NodeLibraryInterface {
    resourceType: ResourceType
}
function NodeLibrary({ resourceType }: NodeLibraryInterface) {
    const [open, setOpen] = useState(false);
    const [NodeLibraryData, setNodeLibraryData] = useState<any>({});
    const [NodeLibraryDataDisplayed, modifyDisplayed] = useState<any>({});

    useEffect(() => {
        if (resourceType === "job") {
            setNodeLibraryData(JobNodeLibraryData)
            modifyDisplayed(JobNodeLibraryData)
        }
        else if (resourceType === "workflow") {
            setNodeLibraryData(WorkflowLibraryData)
            modifyDisplayed(WorkflowLibraryData)
        }
    }, [resourceType])

    const filterCategory = (category) => {
        const newData = {}
        newData[category] = NodeLibraryData[category]
        modifyDisplayed(newData)
    }

    const filterNodeName = (event) => { // TODO: rework this function / rework the NodeLibraryData dict
        const name = event.target.value;
        const newData = {}
        for (var category in NodeLibraryData) {
            for (var node in NodeLibraryData[category]) {
                if (NodeLibraryData[category][node]["node_format"]["name"].toLowerCase().includes(name.toLowerCase())) {
                    if (!(category in newData)) {
                        newData[category] = {}
                    }
                    newData[category][node] = NodeLibraryData[category][node]
                }
            }
        }

        modifyDisplayed(newData)
    }


    return (
        <div cds-theme="dark">
            <div style={{ position: "absolute", zIndex: "3", margin: "15px" }}>
                <CdsButton status="primary" onClick={() => setOpen(true)}>
                    <CdsIcon size="md" shape="plus"></CdsIcon>Add Node</CdsButton>
            </div>
            <CdsModal size="lg" hidden={!open} onCloseChange={() => setOpen(false)}>
                <CdsModalHeader><h2>Node Library</h2></CdsModalHeader>
                <CdsModalContent>
                    <div className='library_main'>
                        <div className='library_navigation'>
                            <CdsSearch style={{ paddingRight: "20px" }}>
                                <label>Search</label>
                                <input type="search" onChange={filterNodeName} />
                            </CdsSearch>
                            <h3>Node category</h3>
                            <div className='library_category'>
                                {Object.keys(NodeLibraryData).map((singleCategory, index) => (
                                    <CdsButton key={"card_button_" + index} action="flat" onClick={() => filterCategory(singleCategory)}>{singleCategory}</CdsButton>
                                ))}
                            </div>
                        </div>
                        <div className='library_content'>
                            {Object.keys(NodeLibraryDataDisplayed).map((singleCategory, index) => (
                                <LibraryCategory
                                    key={"library_category_" + index}
                                    category={singleCategory}
                                    data={NodeLibraryDataDisplayed[singleCategory]}
                                    setOpen={setOpen}
                                />
                            ))}
                        </div>
                    </div>
                </CdsModalContent>
                <CdsModalActions><CdsButton onClick={() => setOpen(false)}>Close</CdsButton></CdsModalActions>
            </CdsModal>
        </div>
    );
}

export default NodeLibrary;

// trigger