import React from 'react';
import '@cds/core/input/register.js';
import '@cds/core/select/register.js';
import '@cds/core/accordion/register.js';
import Parameter from '../Parameter';

class S3Options extends React.Component {

    constructor(props) {
        super(props);
        this.id = props.id;
        this.data = props.data;
        this.onChangeBucket = this.onChangeBucket.bind(this);
        this.onChangeAccessKey = this.onChangeAccessKey.bind(this);
        this.onChangeSecretKey = this.onChangeSecretKey.bind(this);
        this.onChangeEndpointURL = this.onChangeEndpointURL.bind(this);
        this.onChangeRegionName = this.onChangeRegionName.bind(this);
      }
    
    render () {
        return(
            <Parameter
            header="S3 Options">
                <cds-input layout="vertical" key="bucket">
                    <label>Bucket</label>
                    <input id={this.id} name="text" onChange={this.onChangeBucket} defaultValue={this.data.params.connector.options.bucket} className="nodrag" />
                </cds-input>
                <cds-input layout="vertical" key="access_key">
                    <label>Access key</label>
                    <input id={this.id} name="text" type="password" onChange={this.onChangeAccessKey} defaultValue={this.data.params.connector.options.access_key} className="nodrag" />
                </cds-input>
                <cds-input layout="vertical" key="secret_key">
                    <label>Secret key</label>
                    <input id={this.id} name="text" type="password" onChange={this.onChangeSecretKey} defaultValue={this.data.params.connector.options.secret_key} className="nodrag" />
                </cds-input>
                <cds-input layout="vertical" key="endpoint_url">
                    <label>Endpoint URL</label>
                    <input id={this.id} name="text" onChange={this.onChangeEndpointURL} defaultValue={this.data.params.connector.options.endpoint} className="nodrag" />
                    <cds-control-message>optional</cds-control-message>
                </cds-input>
                <cds-input layout="vertical" key="region_name">
                    <label>Region name</label>
                    <input id={this.id} name="text" onChange={this.onChangeRegionName} defaultValue={this.data.params.connector.options.region_name} className="nodrag" />
                    <cds-control-message>optional</cds-control-message>
                </cds-input>
            </Parameter>
        )
    }

    onChangeBucket(evt) {
        this.data.params.connector.options.bucket = evt.target.value;
    }
    
    onChangeAccessKey(evt) {
    this.data.params.connector.options.access_key = evt.target.value;
    }

    onChangeSecretKey(evt) {
    this.data.params.connector.options.secret_key = evt.target.value;
    }

    onChangeEndpointURL(evt) {
    this.data.params.connector.options.endpoint = evt.target.value;
    }

    onChangeRegionName(evt) {
    this.data.params.connector.options.region_name = evt.target.value;
    }

}

export default S3Options;
