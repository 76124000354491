// This function is used to transform a DAG into a Graph
// Use Dagre to position nodes at the end
import Dagre from '@dagrejs/dagre';

import NodeLibraryData from '../../scenes/Draw/components/DrawInterface/components/NodeLibrary/NodeLibraryDataJob.json';


export default function dagToGraph(dag) {
    let edges = []
    let nodes = []
    let nodedata = {}
    console.log(dag)
    for (const task of dag.tasks) {
        console.log(task)
        const [taskFlowType, taskCategory] = typeConversion(task)

        nodes.push(
            {
                "width": 350,
                "height": 250,
                "id": task.id,
                "type": taskFlowType,
                "position": {
                    "x": 0,
                    "y": 0
                },
                "selected": false,
                "positionAbsolute": {
                    "x": 0,
                    "y": 0
                },
                "dragging": false
            }
        )

        nodedata[task.id] = NodeLibraryData[taskCategory][taskFlowType].node_format


        // TODO: handle edge type management (Model or Dataset)
        if (task.dependency) {
            let input_var = 0 

            for (const singleDependency of task.dependency) {

                edges.push(
                    {
                        "source": singleDependency,
                        "sourceHandle": "output_0",
                        "target": task.id,
                        "targetHandle": "input_" + input_var,
                        "type": "custom",
                        "data": {
                            "type": "Dataset"
                        },
                        "id": "reactflow__edge-" + singleDependency + "output_0-" + task.id + "input_" + input_var
                    }
                )
            }
        }

    }

    // Layout the nodes

    const layouted = getLayoutedElements(nodes, edges);

    let flow = {
        "dag": dag,
        "metadata": {
            "edges": layouted.edges,
            "nodes": layouted.nodes,
            "viewport": {"x": 0, "y": 0, "zoom": 1}
        },
        "nodedata": nodedata
    }

    console.log(flow)

    return(
        flow
    )
}

// Returns the Flow type and the Node category
// TODO: improve this function (or remove it if possible)
function typeConversion(task) {
    if (task.type == "write") {

        if (task.params.type == "file") {

            return ["write_" + task.params.connector.type, "Output"]

        } else if (task.params.type == "model") {

            return ["export_model", "Model"]

        }
    }
    else if (task.type == "read") {

        if (task.params.type == "file") {

            return ["read_" + task.params.connector.type, "Input"]

        } else if (task.params.type == "model") {

            return ["import_model", "Input"]

        }
    } else {
        return [task.type, "Transformation"]
    }
}

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, options) => {
  g.setGraph({ rankdir: 'LR' });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) => g.setNode(node.id, node));

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const { x, y } = g.node(node.id);

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};


